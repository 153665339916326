import _ from "lodash";
import React from "react";
import Select from "react-select";
import "./selection-wrapper.scss";
import SelectOptionLayout from "../select-option-layout/select-option-layout";

const SelectWrapper = (props) => {
    const {value, required} = props;

    const SelectWrapper = React.forwardRef((props, ref) => (
        <Select
            ref={ref}
            {...props}
            className="select-wrapper small-select-wrapper"
            components={{Option: SelectOptionLayout}}
            noOptionsMessage={() => "Não há opções"}
        />
    ));

    return (
        <div className="select-wrapper-container small-select-wrapper">
            <SelectWrapper {...props} ref={props.forwardRef} className="small-select-wrapper"/>
            <div style={{position: "absolute"}}>
                <input
                    className="input-required"
                    tabIndex={-1}
                    autoComplete="off"
                    value={value != null ? value : undefined}
                    required={required}
                    onChange={_.noop}
                />
            </div>
        </div>
    );
}

export default SelectWrapper;