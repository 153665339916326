import "./expense-item.scss";
import React from "react";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Accordion, Card, Col, Row, Button, Tooltip, OverlayTrigger} from "react-bootstrap";
import ExpenseItemContent from "../expense-item-content/expense-item-content";
import ProgressBar from 'react-bootstrap/ProgressBar';

const ExpenseItem = (props) => {
    const {expense, onUpdate, onRemove} = props;

    const displayDate = (date) => {
        return moment(date).format('DD/MM/YYYY');
    }

    const displayValue = (value) => {
        return `R$ ${parseFloat(String(value)).toFixed(2).replace(".", ",")}`;
    }

    const renderIdExpense = () => {
        return (
            <div>
                <div className="subtitle">ID</div>
                <div className="title">{expense.idExpense}</div>
            </div>
        );
    }

    const renderExpenseName = () => {
        return (
            <div>
                <div className="subtitle">Despesa</div>
                <div className="title">{expense.name}</div>
            </div>
        );
    }

    const renderPurchaseDate = () => {
        return (
            <div>
                <div className="subtitle">Data de compra</div>
                <div className="title">{displayDate(expense.purchaseDate)}</div>
            </div>
        )
    }

    const renderTotalValue = () => {
        return (
            <div>
                <div className="subtitle">Valor total</div>
                <div className="title">{displayValue(expense.value)}</div>
            </div>
        )
    }

    const renderToggleIcon = () => {
        return (
            <div className="OrdersOrder-toggle-icon">
                <FontAwesomeIcon icon="angle-down" size="sm"/>
            </div>
        );
    }

    const renderAccount = () => {
        return (
            <div>
                <div className="subtitle">Conta</div>
                <div className="title">{expense.account}</div>
            </div>
        )
    }

    const renderInstallments = () => {
        const numberOfInstallments = expense.installments.length;
        let paidInstallments = 0;
        expense.installments.forEach(installment => {
            if (installment.situation === "PAID") {
                paidInstallments++;
            }
        });

        return (
            <div>
                <div className="subtitle">Parcelas pagas</div>
                <ProgressBar now={(paidInstallments / numberOfInstallments) * 100}
                             label={`${paidInstallments}/${numberOfInstallments}`} variant="success"
                             striped></ProgressBar>
            </div>
        )
    }

    const renderExpenseContent = () => {
        return <ExpenseItemContent expense={expense} onRemove={onRemove} onUpdate={onUpdate}/>
    }

    const generateTooltip = () => {
        return (
            <Tooltip id="tooltip">
                <div style={{fontSize: "12px"}}>
                    {expense.description}
                </div>
            </Tooltip>
        );
    }

    return (
        <Accordion className="ExpenseItem">
            <Accordion.Toggle as={Card.Header} className="ExpenseItem-suppress-card-header ExpenseItem-toggle"
                              eventKey="0">
                <Row>
                    <Col md={1}>{renderIdExpense()}</Col>
                    <Col md={4}>
                        <OverlayTrigger overlay={generateTooltip()} placement={"left"}>
                            {renderExpenseName()}
                        </OverlayTrigger>

                    </Col>
                    <Col md={2}>{renderPurchaseDate()}</Col>
                    <Col md={1}>{renderAccount()}</Col>
                    <Col md={2}>{renderInstallments()}</Col>
                    <Col md={2}>{renderTotalValue()}</Col>
                </Row>
                {renderToggleIcon()}

            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    {renderExpenseContent()}
                </Card.Body>
            </Accordion.Collapse>
        </Accordion>
    );
};

export default ExpenseItem;