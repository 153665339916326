import React, {Component} from "react";
import "./expense-item-content.scss";
import _ from "lodash";
import {Button, Col, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";
import InstallmentRemote from "../../../resources/remote/installment-remote"
import autoBind from "auto-bind";

class ExpenseItemContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };

        autoBind(this);
    }

    displayValue(value) {
        return `R$ ${parseFloat(String(value)).toFixed(2).replace(".", ",")}`;
    }

    displayDate(date) {
        return moment(date).format('DD/MM/YYYY');
    }

    handlePayButtonClick(installment) {
        const {expense, onUpdate} = this.props;
        this.setState({isLoading: true});
        InstallmentRemote.pay([installment])
            .then(installments => {
                const expenseCopy = _.cloneDeep(expense);
                installments.forEach(installment => {
                    let installmentCopy = _.find(expenseCopy.installments, _.matchesProperty("idInstallment", installment.idInstallment));
                    Object.assign(installmentCopy, installment);
                });
                onUpdate(expenseCopy);
            })
            .finally(() => this.setState({isLoading: false}));
    }

    renderExpenseInstallments() {
        const {expense} = this.props;
        if (_.isEmpty(expense.installments)) {
            return;
        }

        return (
            <div className="OrdersOrderContent-order-content">
                <div>
                    <Row>
                        <Col>ID</Col>
                        <Col>Parcela</Col>
                        <Col>Vencimento</Col>
                        <Col>Situação</Col>
                        <Col>Valor</Col>
                        <Col>Pago em</Col>
                    </Row>
                    {this.renderExpenseInstallmentsItems()}
                </div>
            </div>
        );
    }

    renderSituationComponent(installment) {
        const {situation} = installment;
        const {isLoading} = this.state;
        if (situation === "PAID") {
            return "Pago"
        }
        return (
            <Button style={{borderRadius: "20px"}}
                    id={"expense-item-content-pay-button-" + installment.idInstallment}
                    size="sm"
                    variant="outline-success"
                    onClick={() => this.handlePayButtonClick(installment)}
                    disabled={isLoading}
            >
                {isLoading ? this.renderLoadingComponent() : "Pagar"}
            </Button>
        );
    }

    renderLoadingComponent() {
        return (
            <Spinner size="sm" animation="border"/>
        );
    }

    renderExpenseInstallmentsItems() {
        const {expense} = this.props;
        return _.orderBy(expense.installments, ["number"], ["asc"]).map(installment => {
            return (
                <Row style={{"marginBottom": "4px"}}>
                    <Col className="subtitle">{installment.idInstallment}</Col>
                    <Col className="subtitle">{installment.number}</Col>
                    <Col className="subtitle">{this.displayDate(installment.dueDate)}</Col>
                    <Col className="subtitle">{this.renderSituationComponent(installment)}</Col>
                    <Col className="subtitle">{this.displayValue(installment.value)}</Col>
                    <Col className="subtitle">{installment.paidIn ? this.displayDate(installment.paidIn): '-'}</Col>
                </Row>
            )
        });
    }

    renderActionButtons() {
        const {expense, onRemove} = this.props;
        return (
            <div style={{display: "inline-block", width: "100%"}}>
                <div className="float-right">
                    {/*<Button*/}
                    {/*    style={{borderRadius: "20px"}}*/}
                    {/*    size="sm"*/}
                    {/*    variant="outline-secondary"*/}
                    {/*    className="mr-2"*/}
                    {/*    onClick={() => onEdit(expense)}*/}
                    {/*>*/}
                    {/*    <FontAwesomeIcon icon="edit" size="sm" className="mr-2"/>*/}
                    {/*    Editar*/}
                    {/*</Button>*/}

                    <Button
                        style={{borderRadius: "20px"}}
                        size="sm"
                        variant="outline-danger"
                        onClick={() => onRemove(expense)}
                    >
                        <FontAwesomeIcon icon="times" size="sm" className="mr-2"/>
                        Apagar
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                <div className="float-right">
                    {this.renderActionButtons()}
                </div>
                <Row>
                    <Col md={6}>
                        {this.renderExpenseInstallments()}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ExpenseItemContent;