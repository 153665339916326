import React, {Component} from "react";
import {Button, Col, Form, InputGroup, Modal, Spinner} from "react-bootstrap";
import _ from "lodash";
import SelectWrapper from "../../utils/select-wrapper/select-wrapper";
import autoBind from "auto-bind";
import DatePickerWrapper from "../../utils/datepicker/datepicker";
import {Accounts, Categories, ExpenseType, Chalets, InstallmentFrequency} from "../../api/expenses";
import moment from 'moment';
import Checkbox from "../../utils/checkbox/checkbox";

class ExpensesModalNewExpense extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: null,
            description: null,
            category: null,
            cabin: null,
            account: null,
            numberOfInstallments: 1,
            purchaseDate: null,
            firstInstallmentDue: null,
            installmentFrequency: this.getInstallmentFrequencyOptions()[0],
            installmentValue: 0,

            value: 0,

            type: null,
            isRecurringExpense: false,
            fixedCost: 0,
            variableCost: 0,

            isSaving: false,
        };

        this._autoFocusComponentRef = null;

        autoBind(this);
    };

    // componentWillMount() {
    //     const {match, isEditMode, expenses} = this.props;
    //     const idExpense = parseInt(match.params.idExpense);
    //
    //     if (isEditMode) {
    //         const expense = _.cloneDeep(_.find(expenses, _.matchesProperty("idExpense", idExpenses)));
    //         this.setState({expenses, isAddCompositionChecked: !_.isEmpty(product.compositions)});
    //     }
    // }

    componentDidMount() {
        this._autoFocusComponentRef.focus();
    }

    handleSave(event) {
        const {onCreate, onUpdate, onClose, isEditMode} = this.props;

        event.preventDefault();
        event.stopPropagation();

        this.setState({isSaving: true});

        const save = isEditMode ? onUpdate : onCreate;

        const expense = this.buildExpense();
        save(expense)
            .then(() => {
                this.setState({isSaving: false});
                onClose();
            });
    }

    buildExpense() {
        const {
            name,
            description,
            category,
            cabin,
            account,
            numberOfInstallments,
            purchaseDate,
            installmentFrequency,
            firstInstallmentDue,
            value,
            type,
            isRecurringExpense,
            fixedCost,
            variableCost
        } = this.state;

        const createdAt = moment().format();

        return {
            name,
            description,
            category: category.value,
            cabin: cabin.value,
            account: account.label,
            installmentFrequency: installmentFrequency.value,
            purchaseDate: moment(purchaseDate).format("YYYY-MM-DD"),
            value,
            type: type.value,
            isRecurringExpense,
            fixedCost,
            variableCost,
            createdAt,
            firstInstallmentDue: moment(firstInstallmentDue).format("YYYY-MM-DD"),
            numberOfInstallments
        };
    }

    getAccountsOptions() {
        const options = Object.entries(Accounts).map(([key, value]) => {
            return {"value": key, "label": value}
        });
        return _.orderBy(options, ["label"], ["asc"]);
    }

    getCategoriesOptions() {
        const options = Object.entries(Categories).map(([key, value]) => {
            return {"value": key, "label": value}
        });
        return _.orderBy(options, ["label"], ["asc"]);
    }

    getTypesOptions() {
        const options = Object.entries(ExpenseType).map(([key, value]) => {
            return {"value": key, "label": value}
        });
        return _.orderBy(options, ["label"], ["asc"]);
    }

    getChaletOptions() {
        const options = Object.entries(Chalets).map(([key, value]) => {
            return {"value": key, "label": value}
        });
        return _.orderBy(options, ["label"], ["asc"]);
    }

    getInstallmentFrequencyOptions() {
        return Object.entries(InstallmentFrequency).map(([key, value]) => {
            return {"value": key, "label": value}
        });
    }

    handleCategorySelectChange(event) {
        if (event == null) {
            return null;
        }

        const category = event;
        this.setState({category});
    }

    handleTypeSelectChange(event) {
        if (event == null) {
            return null;
        }

        const type = event;
        this.setState({type});
    }

    handleAccountSelectChange(event) {
        if (event == null) {
            return null;
        }

        const account = event;
        this.setState({account});
    }

    handleChaletSelectChange(event) {
        if (event == null) {
            return null;
        }

        const cabin = event;
        this.setState({cabin});
    }

    handleInstallmentFrequencySelectChange(event) {
        if (event == null) {
            return null;
        }

        const installmentFrequency = event;
        this.setState({installmentFrequency})
    }

    handleRecurringExpenseCheck(event) {
        const {isRecurringExpense} = this.state;
        this.setState({isRecurringExpense: !isRecurringExpense});
    }

    handlePurchaseDateChange(event) {
        const {firstInstallmentDue} = this.state;
        const purchaseDate = event;
        const minDate = moment(purchaseDate).add(1, "months").startOf("month").toDate();

        const newFirstInstallmentDue = firstInstallmentDue == null ? minDate : firstInstallmentDue;
        this.setState({purchaseDate, firstInstallmentDue: newFirstInstallmentDue});
    }

    handleFirstInstallmentDueChange(event) {
        const firstInstallmentDue = event;
        this.setState({firstInstallmentDue});
    }

    handleExpenseNameChange(event) {
        const name = _.get(event, "target.value");
        this.setState({name});
    }

    handleDescriptionChange(event) {
        const description = _.get(event, "target.value");
        this.setState({description});
    }

    handleNumberOfInstallmentsChange(event) {
        const numberOfInstallments = parseInt(_.get(event, "target.value"));
        this.setState({numberOfInstallments});
    }

    handleValueChange(event) {
        const value = parseFloat(_.get(event, "target.value"));
        this.setState({value});
    }

    handleFixedCostChange(event) {
        const {value} = this.state;
        const fixedCost = parseFloat(_.get(event, "target.value"));
        const variableCost = value - fixedCost;

        if (fixedCost < 0  || fixedCost > value) {
            return;
        }

        this.setState({fixedCost, variableCost});
    }

    handleVariableCostChange(event) {
        const {value} = this.state;
        const variableCost = parseFloat(_.get(event, "target.value"));
        const fixedCost = value - variableCost;

        if (variableCost < 0  || variableCost > value) {
            return;
        }

        this.setState({variableCost, fixedCost});
    }

    renderCategorySelect() {
        const {category} = this.state;
        return (
            <Form.Group>
                <Form.Label style={{fontSize: "14px"}}>Categoria</Form.Label>
                <SelectWrapper
                    isSearchable
                    placeholder="Categoria"
                    required
                    options={this.getCategoriesOptions()}
                    value={category}
                    onChange={this.handleCategorySelectChange}
                />
            </Form.Group>
        )
    }

    renderExpenseTypeSection() {
        const {type} = this.state;
        return (
            <Form.Row>
                <Col md={3}>
                    {this.renderTypeSelect()}
                </Col>
                {(type && [ExpenseType.REGULAR, ExpenseType.SUBSCRIPTION].includes(type.label)) && this.renderSimpleValueField()}
                {(type && ExpenseType.IN_INSTALLMENTS === type.label) && (this.renderInstallmentOptionsSelect())}
            </Form.Row>
        )
    }

    renderSimpleValueField() {
        const {value} = this.state;
        return (
            <Col md={{span: 3, offset: 6}}>
                <Form.Group>
                    <Form.Label style={{fontSize: "14px"}}>Valor</Form.Label>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroupPrepend">R$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            type="number"
                            value={value}
                            onChange={this.handleValueChange}
                        />
                    </InputGroup>
                </Form.Group>
            </Col>
        );
    }

    renderInstallmentOptionsSelect() {
        const {value, numberOfInstallments, installmentFrequency} = this.state;
        return ([
                <Col md={{span: 2, offset: 2}}>
                    <Form.Group>
                        <Form.Label style={{fontSize: "14px"}}>Parcelas</Form.Label>
                        <InputGroup>
                            <Form.Control
                                ref={(input) => {this._autoFocusComponentRef = input;}}
                                type="number"
                                required
                                min={1}
                                step="1"
                                onChange={this.handleNumberOfInstallmentsChange}
                                value={numberOfInstallments}
                            />
                        </InputGroup>
                    </Form.Group>
                </Col>,

                <Col md={{span: 2}}>
                    <Form.Group>
                        <Form.Label style={{fontSize: "14px"}}>Frequência</Form.Label>
                        <SelectWrapper
                            isSearchable
                            required
                            options={this.getInstallmentFrequencyOptions()}
                            value={installmentFrequency}
                            onChange={this.handleInstallmentFrequencySelectChange}
                        />
                    </Form.Group>
                </Col>,
                <Col md={{span: 3}}>
                    <Form.Group>
                        <Form.Label style={{fontSize: "14px"}}>Valor</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroupPrepend">R$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type="number"
                                value={value}
                                onChange={this.handleValueChange}
                                step={"0.1"}
                                min={"0"}
                            />
                        </InputGroup>
                    </Form.Group>
                </Col>
                ]
        );
    }

    renderTypeSelect() {
        const {type} = this.state;
        return (
            <Form.Group>
                <Form.Label style={{fontSize: "14px"}}>Tipo</Form.Label>
                <SelectWrapper
                    isSearchable
                    placeholder="Tipo de compra"
                    required
                    options={this.getTypesOptions()}
                    value={type}
                    onChange={this.handleTypeSelectChange}
                />
            </Form.Group>
        )
    }

    renderTotalValueDisplay() {
        const {value, type} = this.state;
        const label = type?.label === ExpenseType.SUBSCRIPTION ? "Valor mensal" : "Valor final";

        return (
            <Form.Group>
                <Form.Label className={"subtitle"} style={{fontSize: "14px"}}>{label}</Form.Label>
                <div  style={{fontSize: "20px"}}>
                    <b>R$ {parseFloat(value.toFixed(2))}</b>
                </div>
            </Form.Group>
        );
    }

    renderInstallmentValueDisplay() {
        const {value, numberOfInstallments} = this.state;
        const installmentValue = value / numberOfInstallments;
        return (
            <Form.Group>
                <Form.Label className={"subtitle"} style={{fontSize: "14px"}}>Valor da parcela</Form.Label>
                <div  style={{fontSize: "20px"}}>
                    <b>R$ {parseFloat(installmentValue.toFixed(2))}</b>
                </div>
            </Form.Group>
        );
    }

    renderValueDisplays() {
        const {type} = this.state;
        const hasInstallments = type && type.label === ExpenseType.IN_INSTALLMENTS;

        return (
            <Form.Row style={{marginTop: "24px"}}>
                {hasInstallments && <Col md={{span: 2, offset: 8}}>
                    {this.renderInstallmentValueDisplay()}
                </Col>}
                <Col md={hasInstallments ? {span: 2} : {span: 2, offset: 10}}>
                    {this.renderTotalValueDisplay()}
                </Col>
            </Form.Row>
        );
    }

    renderChaletSelect() {
        const {cabin} = this.state;
        return (
            <Form.Group>
                <Form.Label style={{fontSize: "14px"}}>Cabana</Form.Label>
                <SelectWrapper
                    isSearchable
                    placeholder="Cabana"
                    required
                    options={this.getChaletOptions()}
                    value={cabin}
                    onChange={this.handleChaletSelectChange}
                />
            </Form.Group>
        )
    }

    renderAccountSelect() {
        const {account} = this.state;
        return (
            <Form.Group>
                <Form.Label style={{fontSize: "14px"}}>Conta</Form.Label>
                <SelectWrapper
                    isSearchable
                    placeholder="Conta"
                    required
                    options={this.getAccountsOptions()}
                    value={account}
                    onChange={this.handleAccountSelectChange}
                />
            </Form.Group>
        );
    }

    renderPurchaseDateDatePicker() {
        const {purchaseDate} = this.state;
        return (
            <Form.Group>
                <Form.Label style={{fontSize: "14px"}}>Data</Form.Label>
                <DatePickerWrapper
                    onSelect={this.handlePurchaseDateChange}
                    selected={purchaseDate}
                    required
                />
            </Form.Group>
        );
    }

    renderFirstInstallmentDueDatePicker() {
        const {firstInstallmentDue, purchaseDate} = this.state;

        const minDate = purchaseDate != null ? moment(purchaseDate).add(1, "months").startOf("month").toDate() : null;

        return (
            <Form.Group>
                <Form.Label style={{fontSize: "14px"}}>Vencimento 1ª parcela</Form.Label>
                <DatePickerWrapper
                    onSelect={this.handleFirstInstallmentDueChange}
                    selected={firstInstallmentDue}
                    minDate={minDate}
                    required
                />
            </Form.Group>
        );
    }

    renderInstallmentFrequencySelect() {
        const {installmentFrequency} = this.state;
        return (
            <Form.Group>
                <Form.Label style={{fontSize: "14px"}}>Frequência</Form.Label>
                <SelectWrapper
                    isSearchable
                    required
                    options={this.getInstallmentFrequencyOptions()}
                    value={installmentFrequency}
                    onChange={this.handleInstallmentFrequencySelectChange}
                />
            </Form.Group>
        );
    }

    renderRecurringExpenseCheckbox() {
        const {isRecurringExpense} = this.state;
        return (
            <Form.Group>
                <Checkbox id={"expenses-recurring-expense-checkbox"}
                          checked={isRecurringExpense}
                          onChange={this.handleRecurringExpenseCheck}
                          label={"É uma despesa recorrente?"}
                          className={"title"}
                />
            </Form.Group>
        )
    }

    renderFixedCostField() {
        const {value, fixedCost, variableCost, isRecurringExpense} = this.state;

        if (!isRecurringExpense) {
            return;
        }

        const isInvalid = variableCost + fixedCost !== value;

        return (
            <Form.Group>
                <Form.Label style={{fontSize: "14px"}}>Custo fixo</Form.Label>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">R$</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        type="number"
                        required
                        isInvalid={isInvalid}
                        min={0}
                        step="0.01"
                        max={value}
                        onChange={this.handleFixedCostChange}
                        value={parseFloat(fixedCost.toFixed(2))}
                    />
                </InputGroup>
                {isInvalid ?
                <Form.Text>
                    A soma dos custos deve ser igual ao valor da despesa
                </Form.Text> : null}
            </Form.Group>
        );
    }

    renderVariableCostField() {
        const {value, fixedCost, variableCost, isRecurringExpense} = this.state;

        if (!isRecurringExpense) {
            return;
        }

        const isInvalid = variableCost + fixedCost !== value;

        return (
            <Form.Group>
                <Form.Label style={{fontSize: "14px"}}>Custo variável</Form.Label>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">R$</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        type="number"
                        required
                        isInvalid={isInvalid}
                        min={0}
                        step="0.01"
                        max={value}
                        onChange={this.handleVariableCostChange}
                        value={parseFloat(variableCost.toFixed(2))}
                    />
                </InputGroup>
            </Form.Group>
        );
    }

    renderExpenseField() {
        const {name} = this.state;
        return (
            <Form.Group>
                <Form.Label style={{fontSize: "14px"}}>Despesa</Form.Label>
                <InputGroup>
                    <Form.Control
                        ref={(input) => {this._autoFocusComponentRef = input;}}
                        type="text"
                        required
                        onChange={this.handleExpenseNameChange}
                        value={name}
                    />
                </InputGroup>
            </Form.Group>
        );
    }

    renderDescriptionField() {
        const {description} = this.state;
        return (
            <Form.Group>
                <Form.Label style={{fontSize: "14px"}}>Descrição</Form.Label>
                <InputGroup>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        onChange={this.handleDescriptionChange}
                        value={description}
                    />
                </InputGroup>
                <Form.Text muted>Opcional</Form.Text>
            </Form.Group>
        );
    }

    render() {
        const {isSaving, isRecurringExpense, numberOfInstallments, value, fixedCost, variableCost} = this.state;
        const {onClose, isEditMode} = this.props;

        const invalidCostValues = isRecurringExpense && (fixedCost + variableCost !== value)

        return (
            <Modal show size="lg" backdrop="static">
                <Modal.Header>
                    <Modal.Title>
                        {isEditMode ? "Atualizar " : "Nova "} despesa
                    </Modal.Title>
                </Modal.Header>


                <Modal.Body>
                    <Form id="products-modal-add-product-form" onSubmit={this.handleSave}>
                        <Form.Group>
                            {this.renderExpenseField()}
                            {this.renderDescriptionField()}

                            <Form.Row>
                                <Col md={3}>
                                    {this.renderPurchaseDateDatePicker()}
                                </Col>

                                <Col md={3}>
                                    {this.renderCategorySelect()}
                                </Col>

                                <Col md={3}>
                                    {this.renderChaletSelect()}
                                </Col>

                                <Col md={3}>
                                    {this.renderAccountSelect()}
                                </Col>

                            </Form.Row>

                            {this.renderExpenseTypeSection()}

                            <Form.Row>
                                <Col md={8}></Col>

                            </Form.Row>

                            <Form.Row>
                                <Col md={4}>
                                    {this.renderRecurringExpenseCheckbox()}
                                </Col>
                                <Col md={{span: 3, offset: 2}}>
                                    {this.renderFixedCostField()}
                                </Col>
                                <Col md={{span: 3}}>
                                    {this.renderVariableCostField()}
                                </Col>
                            </Form.Row>

                            {this.renderValueDisplays()}

                        </Form.Group>
                    </Form>

                </Modal.Body>

                <Modal.Footer>
                    <Button className="mr-auto"
                            onClick={onClose}
                            variant="light"
                    >
                        Cancelar
                    </Button>
                    <button className="btn btn-success" type="submit" form="products-modal-add-product-form" disabled={isSaving || invalidCostValues}>
                        {isSaving ?
                            <div>
                                <Spinner animation="border" role="status" size="sm" className="mr-2"/>
                                Salvando
                            </div> : <div>Salvar</div>
                        }
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ExpensesModalNewExpense;