import React, {Component} from "react";
import "./login.scss";
import {Button, Col, Form, InputGroup, Row, Spinner} from "react-bootstrap";
import {Link, Route, withRouter} from "react-router-dom";
import logo from "../logo.jpg";
import Register from "./register";
import autoBind from "auto-bind";
import * as LoginRemote from "../resources/remote/login-remote";
import alertify from "alertifyjs";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actions: {
                showPassword: false
            },
            isLoading: false,
            email: null,
            password: null
        };

        autoBind(this);
    }

    handleSubmit(event) {
        const {email, password, isLoading} = this.state;
        const {history, onLogin} = this.props;

        event.preventDefault();
        event.stopPropagation();

        if (isLoading) {
            return;
        }

        this.setState({isLoading: true})

        return LoginRemote.login(email, password)
            .then((response) => {
                const {token, duration} = response;
                const login = {email, password, token, duration};
                onLogin(login);
                history.push("/expenses");
            })
            .catch((error) => {
                onLogin(null);
                alertify.error("Erro ao realizar login!");
            })
            .finally(() => {
                this.setState({isLoading: false});
            })
    }

    handleShowPassword() {
        const {actions} = this.state;
        actions.showPassword = !actions.showPassword;
        this.setState({actions});
    }

    handleEmail(event) {
        const email = event.target.value;
        this.setState({email});
    }

    handlePassword(event) {
        const password = event.target.value;
        this.setState({password});
    }

    renderFieldEmail() {
        const {isLoading} = this.state;
        return (
            <Form.Group>
                <Form.Label>
                    E-mail
                </Form.Label>
                <Form.Control
                    type="email"
                    required
                    onChange={this.handleEmail}
                    disabled={isLoading}
                />
            </Form.Group>
        );
    }

    renderFieldPassword() {
        const {isLoading} = this.state;
        return (
            <Form.Group>
                <Form.Label>
                    Senha
                </Form.Label>
                <InputGroup>
                    <Form.Control
                        type="password"
                        required
                        onChange={this.handlePassword}
                        disabled={isLoading}
                    />
                </InputGroup>
            </Form.Group>
        );
    }

    renderButtonLogin() {
        const {isLoading} = this.state;
        return (
            <Button className="Login-login-button" type="submit" disabled={isLoading}>
                {isLoading ?
                    <div>
                        <Spinner animation="border" role="status" size="sm" className="mr-2"/>
                        Entrando
                    </div> : <div>Entrar</div>
                }
            </Button>
        );
    }

    renderLinkRegister() {
        if (process.env.REACT_APP_ENABLE_ACCOUNT_CREATION === 'false')
            return;

        return (
            <Link to="/register">
                Não possui uma conta? Cadastre-se agora!
            </Link>
        );
    }

    renderLoginSection() {
        const loginSection = (props) => (
            <Form className="Login-login-form" {...props}
                  onSubmit={this.handleSubmit}
            >
                {this.renderFieldEmail()}
                {this.renderFieldPassword()}
                {this.renderButtonLogin()}
                {this.renderLinkRegister()}
            </Form>
        );

        return (
            <Route exact path="/" render={loginSection}/>
        );
    }

    renderInfoSection() {
        return (
            <div>
                <img className="Login-logo" src={logo} alt="Sphinx logo"/>
                <h3 className="Login-info-subtitle">Cabanas Doce Recanto</h3>
            </div>

        )
    }

    renderRegisterSection() {
        return (
            <Route path="/register" component={Register}/>
        )
    }

    render() {
        return (
            // <div className="Login">
            //     <div className="Login-container">
            //         <div className="Login-info">
            //             {this.renderInfoSection()}
            //         </div>
            //         <div className="Login-divider-container">
            //             <div className="Login-divider"/>
            //         </div>
            //         <div className="Login-login">
            //             {this.renderLoginSection()}
            //             {this.renderRegisterSection()}
            //         </div>
            //     </div>
            // </div>

            <div className="Login">
                <div className="Login-container">
                    <Row>
                        <Col>
                            <div className="Login-info">
                                {this.renderInfoSection()}
                            </div>
                        </Col>
                        <Col>
                            <div className="Login-login">
                                {this.renderLoginSection()}
                                {this.renderRegisterSection()}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>


        );
    }
}

export default withRouter(Login);