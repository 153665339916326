import _ from "lodash";
import * as LocalStorage from "../resources/localStorage";

const getHeaders = (authorization) => new Headers({
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': getAuthorization(authorization)
});

const getAuthorization = (authorization) => {
    if (authorization == null) {
        return `Bearer ${LocalStorage.getToken()}`;
    }

    const {email, password} = authorization;
    return `Basic: ${btoa(`${email}:${password}`)}`
}

export const urlBase = getUrlBase();

function getUrlBase() {
    const environment = process.env.NODE_ENV;
    switch (environment) {
        case "production":
            return process.env.REACT_APP_PROD_BACKEND_URL;
        case "development":
        default:
            return process.env.REACT_APP_DEV_BACKEND_URL;
    }
}

export const getParams = ({authorization} = {}) => {
    return {
        method: 'GET',
        headers: getHeaders(authorization),
        mode: 'cors',
        cache: 'default'
    };
};

export const postParams = ({body} = {}) => {
    return {
        method: "POST",
        headers: getHeaders(),
        mode: 'cors',
        cache: 'default',
        body: JSON.stringify(body)
    };
};

export const putParams = ({body}) => {
    return {
        method: "PUT",
        headers: getHeaders(),
        mode: 'cors',
        cache: 'default',
        body: JSON.stringify(body)
    }
};

export const deleteParams = () => {
    return {
        method: 'DELETE',
        headers: getHeaders(),
        mode: 'cors',
        cache: 'default'
    };
};

export const params = {
    get: getParams,
    put: putParams,
    post: postParams,
    delete: deleteParams
};

export const resolve = (response) => {
    const error = _.get(response, "error", null)
    if (error != null) {
        return Promise.reject(error);
    }
    return Promise.resolve(response);
}