import axios from "axios";

const url = getUrlBase();

function getUrlBase() {
    const environment = process.env.NODE_ENV;
    switch (environment) {
        case "production":
            return process.env.REACT_APP_PROD_CHECKLIST_PDF_URL;
        case "development":
        default:
            return process.env.REACT_APP_DEV_CHECKLIST_PDF_URL;
    }
}

function generate(delivery) {
    return axios.post(url, delivery)
        .then(response => {
            const buffer = response.data;
            return new Promise((resolve, reject) => {
                resolve(new Blob([new Uint8Array(buffer.data)], {type: 'application/pdf'}));
            });
        });
}

const ChecklistsRemote = {
    generate
};

export default ChecklistsRemote;