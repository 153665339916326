import {urlBase, params, resolve} from "../resources";

const url = `${urlBase}/api/pix`

function create(pixKey) {
    const content = {body: pixKey}
    return fetch(url, params.put(content))
        .then(response => response.json())
        .then(resolve)
}

function update(pixKey) {
    const content = {body: pixKey};
    return fetch(url, params.post(content))
        .then(response => response.json())
        .then(resolve)
}

function get(idPixKey) {
    return fetch(`${url}/${idPixKey}`, params.get())
        .then(response => response.json())
        .then(resolve);
}

function list() {
    return fetch(`${url}/list`, params.get())
        .then(response => response.json())
        .then(resolve);
}

function remove(idPixKey) {
    return fetch(`${url}/${idPixKey}`, params.delete())
        .then(response => response.json())
        .then(resolve);
}

const PixRemote = {
    create,
    update,
    get,
    list,
    remove
};

export default PixRemote;