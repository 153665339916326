import {params, resolve} from "../resources";

const url = "https://brasilapi.com.br/api/cep/v1/";

function get(cep) {
    return fetch(url + String(cep), params.get())
        .then(response => response.json())
        .then(resolve);
}

const CepRemote = {
    get
};

export default CepRemote;