import React, {Component} from "react";
import autoBind from "auto-bind";
import alertify from "alertifyjs";
import {Button, Col, Form, InputGroup, Row, Spinner} from "react-bootstrap";
import PixRemote from "../../../resources/remote/pix-remote";
import _ from "lodash";

export class PixCreateKey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actions: {
                isSaving: false
            },
            idPixKey: null,
            description: null,
            value: null,
        }

        this._isEditMode = false;
        autoBind(this);
    }

    async componentWillMount() {
        const {match} = this.props;
        const idPixKey = match.params.idPixKey;

        if (!_.isNil(idPixKey)) {
            const pixKey = await PixRemote.get(idPixKey);
            this._isEditMode = true;
            this.setState({...pixKey});
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(nextProps, this.props)
            || !_.isEqual(nextState, this.state);
    }

    closePage() {
        const {history} = this.props;
        history.push("/pix")
    }

    handleSubmit(event) {
        const {actions, idPixKey, description, value} = this.state;

        event.preventDefault();
        event.stopPropagation();

        const pixKey = {idPixKey, description, value};
        actions.isSaving = true;
        this.setState({actions})

        const save = this._isEditMode ? PixRemote.update : PixRemote.create;
        save(pixKey)
            .then(pixKey => {
                this.closePage();
                alertify.success(this._isEditMode ? "Chave atualizada!" : "Chave adicionada!");
            })
            .catch(error => alertify.error("Não foi possível adicionar a chave.\n" + error))
            .finally(() => {
                actions.isSaving = false;
                this.setState({actions});
            });
    }

    handleDescriptionChange(event) {
        const description = event.target.value;
        this.setState({description})
    }

    handleValueChange(event) {
        const value = event.target.value;
        this.setState({value});
    }

    renderDescriptionField() {
        const {description} = this.state;
        return (
            <Form.Group>
                <Form.Label>Descrição</Form.Label>
                <InputGroup>
                    <Form.Control
                        type="text"
                        onChange={this.handleDescriptionChange}
                        required
                        value={description}
                    />
                </InputGroup>
            </Form.Group>
        );
    }

    renderValueField() {
        const {value} = this.state;
        return (
            <Form.Group>
                <Form.Label>Valor</Form.Label>
                <InputGroup>
                    <Form.Control
                        type="text"
                        required
                        onChange={this.handleValueChange}
                        value={value}
                    />
                </InputGroup>
            </Form.Group>
        );
    }

    renderCancelButton() {
        const {actions} = this.state;

        return (
            <Button className="float-left" variant="outline-danger" disabled={actions.isSaving}
                    onClick={this.closePage}>
                Cancelar
            </Button>
        );
    }

    renderSaveButton() {
        const {actions} = this.state;
        const disabled = actions.isSaving;

        return (
            <Button className="float-right" variant="success" type="submit" disabled={disabled}>
                {actions.isSaving ?
                    <div>
                        <Spinner animation="border" role="status" size="sm" className="mr-2"/>
                        Salvando
                    </div> : <div>Salvar</div>
                }
            </Button>
        )
    }

    render() {
        return (
            <div>
                <div style={{display: "inline-block", width: "100%"}}>
                    <h3 className="float-left">{this._isEditMode ? "Atualizar" : "Nova"} chave</h3>
                </div>

                    <Form onSubmit={this.handleSubmit}>
                        <Form.Row>
                            <Col md={6}>
                                {this.renderDescriptionField()}
                            </Col>

                            <Col md={6}>
                                {this.renderValueField()}
                            </Col>

                        </Form.Row>
                        {this.renderSaveButton()}
                        {this.renderCancelButton()}
                    </Form>
            </div>
        )
    }
}