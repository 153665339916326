import {urlBase, params, resolve} from "../resources";

const url = urlBase + "/api/stock";

export function list() {
    return fetch(url + "/list", params.get())
        .then(response => response.json())
        .then(resolve);
}

export const update = (stock) => {
    const content = {body: stock};
    return fetch(url, params.put(content))
        .then(response => response.json())
        .then(resolve);
}