import React, {Component} from "react";
import autoBind from "auto-bind";
import {Accordion, Button, Card, Col, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DatePickerWrapper from "../../../utils/datepicker/datepicker";
import Checkbox from "../../../utils/checkbox/checkbox";
import SelectWrapper from "../../../utils/select-wrapper/select-wrapper";
import _ from "lodash";
import {Accounts, Categories, Chalets} from "../../../api/expenses";
import moment from "moment/moment";
import "./installments-content-filter.scss";


class InstallmentsContentFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expenseName: null,
            startDate: null,
            endDate: null,
            isPaid: false,
            isNotPaid: true,
            accounts: [],
            chalets: [],
            categories: [],

            filteredExpenses: [],
            filteredInstallments: []
        }
        autoBind(this);
    }

    componentDidMount() {
        this.applyDefaultFilter();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.handleFilterChange();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.props, nextProps)
            || !_.isEqual(this.state, nextState);
    }

    applyDefaultFilter() {
        this.handleDateCurrentMonthButtonClick();
    }

    displayValue(value) {
        return `R$ ${parseFloat(String(value)).toFixed(2).replace(".", ",")}`;
    }

    getAccountsOptions() {
        const options = Object.entries(Accounts).map(([key, value]) => {
            return {"value": key, "label": value}
        });
        return _.orderBy(options, ["label"], ["asc"]);
    }

    getCategoriesOptions() {
        const options = Object.entries(Categories).map(([key, value]) => {
            return {"value": key, "label": value}
        });
        return _.orderBy(options, ["label"], ["asc"]);
    }

    getChaletOptions() {
        const options = Object.entries(Chalets).map(([key, value]) => {
            return {"value": key, "label": value}
        });
        return _.orderBy(options, ["label"], ["asc"]);
    }

    getFirstDateOfCurrentMonth() {
        return moment().startOf('month').toDate();
    }

    getLastDateOfCurrentMonth() {
        return moment().endOf('month').toDate();
    }

    getFirstDateOfPreviousMonth() {
        return moment().subtract(1, 'months').startOf('month').toDate();
    }

    getLastDateOfPreviousMonth() {
        return moment().subtract(1, 'months').endOf('month').toDate();
    }

    getFirstDateOfPreviousYear() {
        return moment().subtract(1, 'years').startOf('year').toDate();
    }

    getLastDateOfPreviousYear() {
        return moment().subtract(1, 'years').endOf('year').toDate();
    }

    getFirstDateOfCurrentYear() {
        return moment().startOf('year').toDate();
    }

    getLastDateOfCurrentYear() {
        return moment().endOf('year').toDate();
    }

    getFirstDateOfNextYear() {
        return moment().add(1, 'years').startOf('year').toDate();
    }

    getLastDateOfNextYear() {
        return moment().add(1, 'years').endOf('year').toDate();
    }

    getNumberOfInstallments() {
        const {filteredInstallments} = this.state;
        return filteredInstallments.length;
    }

    getAccumulatedValue() {
        const {filteredInstallments} = this.state;
        return filteredInstallments.reduce(((accumulatedValue, installment) => accumulatedValue += installment.value), 0);
    }

    handleExpenseNameChange(event) {
        const expenseName = _.get(event, "target.value");
        this.setState({expenseName});
    }

    handleStartDateChange(event) {
        const startDate = event;
        this.setState({startDate});
    }

    handleEndDateChange(event) {
        const endDate = event;
        this.setState({endDate});
    }

    handlePaidCheckBoxChange() {
        const {isPaid} = this.state;
        this.setState({isPaid: !isPaid});
    }

    handleNotPaidCheckBoxChange() {
        const {isNotPaid} = this.state;
        this.setState({isNotPaid: !isNotPaid});
    }

    handleCategoriesSelectChange(event) {
        if (event == null) {
            return null;
        }

        const categories = event;
        this.setState({categories});
    }

    handleAccountsSelectChange(event) {
        if (event == null) {
            return null;
        }

        const accounts = event;
        this.setState({accounts});
    }

    handleChaletsSelectChange(event) {
        if (event == null) {
            return null;
        }

        const chalets = event;
        this.setState({chalets});
    }

    handleAccountsSelectButtonClick() {
        const accounts = this.getAccountsOptions()
        this.setState({accounts});
    }

    handleChaletsSelectButtonClick() {
        const chalets = this.getChaletOptions()
        this.setState({chalets});
    }

    handleCategoriesSelectButtonClick() {
        const categories = this.getCategoriesOptions()
        this.setState({categories});
    }

    handleDatePreviousMonthButtonClick() {
        const startDate = this.getFirstDateOfPreviousMonth();
        const endDate = this.getLastDateOfPreviousMonth();
        this.setState({startDate, endDate});
    }

    handleDateCurrentMonthButtonClick() {
        const startDate = this.getFirstDateOfCurrentMonth();
        const endDate = this.getLastDateOfCurrentMonth();
        this.setState({startDate, endDate});
    }

    handleDateNextMonthButtonClick() {
        const startDate = moment().add(1, 'months').startOf('month').toDate();
        const endDate = moment().add(1, 'months').endOf('month').toDate();
        this.setState({startDate, endDate});
    }

    handleDatePreviousYearButtonClick() {
        const startDate = this.getFirstDateOfPreviousYear()
        const endDate = this.getLastDateOfPreviousYear()
        this.setState({startDate, endDate});
    }

    handleDateCurrentYearButtonClick() {
        const startDate = this.getFirstDateOfCurrentYear()
        const endDate = this.getLastDateOfCurrentYear()
        this.setState({startDate, endDate});
    }

    handleDateNextYearButtonClick() {
        const startDate = this.getFirstDateOfNextYear()
        const endDate = this.getLastDateOfNextYear()
        this.setState({startDate, endDate});
    }

    handleFilterChange() {
        const {expenseName, startDate, endDate, isPaid, isNotPaid, accounts, chalets, categories} = this.state;
        const {expenses, onFilter} = this.props;

        const filteredInstallments = expenses.map(expense => {
            const installments = expense.installments;
            return installments.filter(installment => this.filterInstallment(installment, expenseName, startDate, endDate, accounts, chalets, categories, isPaid, isNotPaid))
                .map(installment => {
                    installment.expenseName = expense.name;
                    installment.account = expense.account;
                    installment.chalets = expense.chalets;
                    installment.categories = expense.categories;
                    installment.numberOfInstallments = expense.installments.length;
                    return installment;
                });
        }).flat();

        this.setState({filteredInstallments});

        onFilter(filteredInstallments);
    }

    filterExpense(expense, nameFilter, accountsFilter, chaletsFilter, categoriesFilter) {
        const {name, account, cabin, categories} = expense;

        const filterByName = nameFilter ? () => {
            return _.lowerCase(name).includes(_.lowerCase(nameFilter));
        } : () => true;

        const filterByAccounts = accountsFilter.length ? () => {
            return _.map(accountsFilter, 'label').includes(account);
        } : () => true;

        const filterByChalet = chaletsFilter.length ? () => {
            return _.map(chaletsFilter, 'value').includes(cabin);
        } : () => true;

        const filterByCategories = categoriesFilter.length ? () => {
            return categories.some(item => _.map(categoriesFilter, 'label').includes(item));
        } : () => true;


        return filterByName(name, nameFilter)
            && filterByAccounts()
            && filterByChalet()
            && filterByCategories();
    }

    filterInstallment(installment, expenseNameFilter, startDateFilter, endDateFilter, accountsFilter, chaletsFilter, categoriesFilter, isPaid, isNotPaid) {
        const {dueDate, expenseName, situation, account, chalets, categories} = installment;

        const filterByExpenseName = expenseNameFilter ? () => {
            return _.lowerCase(expenseName).includes(_.lowerCase(expenseNameFilter));
        } : () => true;

        const filterByDate = (startDateFilter && endDateFilter) ? () => {
            return moment(dueDate).isBetween(startDateFilter, endDateFilter, undefined, '[]');
        } : () => true;

        const filterByAccounts = accountsFilter.length ? () => {
            return _.map(accountsFilter, 'label').includes(account);
        } : () => true;

        const filterByChalet = chaletsFilter.length ? () => {
            return chalets.some(item => _.map(chaletsFilter, 'label').includes(item));
        } : () => true;

        const filterByCategories = categoriesFilter.length ? () => {
            return categories.some(item => _.map(categoriesFilter, 'label').includes(item));
        } : () => true;


        const filterBySituation = () => {
            return (isPaid && situation === 'PAID') || (isNotPaid && situation === 'NOT_PAID')
        }

        return filterByExpenseName()
            && filterByDate()
            && filterByAccounts()
            && filterByChalet()
            && filterByCategories()
            && filterBySituation();
    }

    renderToggleIcon() {
        return (
            <div className="OrdersOrder-toggle-icon">
                <FontAwesomeIcon icon="angle-down" size="sm"/>
            </div>
        );
    }

    renderExpenseField() {
        const {name} = this.state;
        return (
            <div>
                <div className={"subtitle"}>Despesa</div>
                <Form.Control
                    className="text-field"
                    ref={(input) => {this._autoFocusComponentRef = input;}}
                    type="text"
                    required
                    onChange={this.handleExpenseNameChange}
                    value={name}
                    style={{textSize: "12px"}}
                />
            </div>
        );
    }

    renderStartDateDatePicker() {
        const {startDate, endDate} = this.state;
        const maxDate = endDate != null ? endDate : null;
        return (
            <div>
                <div className={"subtitle"}>Data inicial</div>
                <DatePickerWrapper
                    className="text-field"
                    onSelect={this.handleStartDateChange}
                    selected={startDate}
                    maxDate={maxDate}
                    required
                />
            </div>
        );
    }

    renderEndDateDatePicker() {
        const {endDate, startDate} = this.state;
        const minDate = startDate != null ? startDate : null;
        return (
            <div>
                <div className={"subtitle"}>Data final</div>
                <DatePickerWrapper
                    className="text-field"
                    onSelect={this.handleEndDateChange}
                    selected={endDate}
                    required
                    minDate={minDate}
                />
            </div>
        );
    }

    renderPaidCheckBox() {
        const {isPaid} = this.state;
        return (
            <Checkbox id={"expenses-content-filter-paid-checkbox-id"}
                      checked={isPaid}
                      onChange={this.handlePaidCheckBoxChange}
                      label={"Pago"}
                      className={"subtitle"}
            />
        )
    }

    renderNotPaidCheckBox() {
        const {isNotPaid} = this.state;
        return (
            <Checkbox id={"expenses-content-filter-not-paid-checkbox-id"}
                      checked={isNotPaid}
                      label={"Em aberto"}
                      className={"subtitle"}
                      onChange={this.handleNotPaidCheckBoxChange}
            />
        )
    }

    renderSituationCheckbox() {
        return (
            <div>
                {this.renderPaidCheckBox()}
                {this.renderNotPaidCheckBox()}
            </div>
        )
    }

    renderCategoriesSelect() {
        const {categories} = this.state;
        return (
            <div>
                <div className={"subtitle"}>Categorias</div>
                <SelectWrapper
                    className="text-field"
                    isSearchable
                    placeholder="Selecione"
                    required
                    isMulti
                    options={this.getCategoriesOptions()}
                    value={categories}
                    onChange={this.handleCategoriesSelectChange}
                />
            </div>
        )
    }

    renderChaletsSelect() {
        const {chalets} = this.state;
        return (
            <div>
                <div className={"subtitle"}>Cabanas</div>
                <SelectWrapper
                    isSearchable
                    placeholder="Selecione"
                    required
                    isMulti
                    options={this.getChaletOptions()}
                    value={chalets}
                    onChange={this.handleChaletsSelectChange}
                />
            </div>
        )
    }

    renderAccountsSelect() {
        const {accounts} = this.state;
        return (
            <div>
                <div className={"subtitle"}>Contas</div>
                <SelectWrapper
                    isSearchable
                    placeholder="Selecione"
                    required
                    isMulti
                    options={this.getAccountsOptions()}
                    value={accounts}
                    onChange={this.handleAccountsSelectChange}
                />
            </div>
        );
    }

    renderDatePreviousMonthButton() {
        return (
            <Button className="float-left small-btn" style={{borderRadius: "20px", fontSize: "12px", marginRight: "4px"}}
                    size="sm"
                    onClick={this.handleDatePreviousMonthButtonClick}
                    variant={"outline-primary"}
            >
                Mês anterior
            </Button>
        )
    }

    renderDateCurrentMonthButton() {
        return (
            <Button className="float-left small-btn" style={{borderRadius: "20px", fontSize: "12px", marginRight: "4px"}}
                    size="sm"
                    onClick={this.handleDateCurrentMonthButtonClick}
                    variant={"outline-primary"}
            >
                Mês atual
            </Button>
        )
    }

    renderDateNextMonthButton() {
        return (
            <Button className="float-left small-btn" style={{borderRadius: "20px", fontSize: "12px", marginRight: "4px"}}
                    size="sm"
                    onClick={this.handleDateNextMonthButtonClick}
                    variant={"outline-primary"}
            >
                Próximo mês
            </Button>
        )
    }

    renderDatePreviousYearButton() {
        return (
            <Button className="float-left small-btn" style={{borderRadius: "20px", fontSize: "12px", marginRight: "4px"}}
                    size="sm"
                    onClick={this.handleDatePreviousYearButtonClick}
                    variant={"outline-primary"}
            >
                Ano passado
            </Button>
        )
    }

    renderDateCurrentYearButton() {
        return (
            <Button className="float-left small-btn" style={{borderRadius: "20px", fontSize: "12px", marginRight: "4px"}}
                    size="sm"
                    onClick={this.handleDateCurrentYearButtonClick}
                    variant={"outline-primary"}
            >
                Ano atual
            </Button>
        )
    }

    renderDateNextYearButton() {
        return (
            <Button className="float-left small-btn" style={{borderRadius: "20px", fontSize: "12px", marginRight: "4px"}}
                    size="sm"
                    onClick={this.handleDateNextYearButtonClick}
                    variant={"outline-primary"}
            >
                Próximo ano
            </Button>
        )
    }

    renderInstallmentsSummary() {
        return (
            <div>
                <div className="float-right">
                    <div className="subtitle">Valor acumulado</div>
                    <div className="title">{this.displayValue(this.getAccumulatedValue())}</div>
                </div>
                <div className="float-right" style={{marginRight: "16px"}}>
                    <div className="subtitle">Parcelas</div>
                    <div className="title">{this.getNumberOfInstallments()}</div>
                </div>
            </div>

        )
    }


    render() {
        const {installments} = this.props;
        return (
            <Accordion className="InstallmentsContentFilter" defaultActiveKey="0">
                <Accordion.Toggle as={Card.Header} className="InstallmentsContentFilter-suppress-card-header InstallmentsContentFilter-toggle"
                                  eventKey="0">
                    Filtrar
                    {this.renderToggleIcon()}

                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" style={{paddingBottom: "12px"}}>
                    <Card.Body className="card-body">
                        <Row>
                            <Col md={3} className="small-col">
                                {this.renderExpenseField()}
                            </Col>
                            <Col md={2} className="small-col">
                                <Row>
                                    <Col>
                                        {this.renderStartDateDatePicker()}
                                    </Col>
                                    <Col>
                                        {this.renderEndDateDatePicker()}
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1}>
                                {this.renderSituationCheckbox()}
                            </Col>
                            <Col>
                                <Row>
                                    <Col className="small-col">
                                        {this.renderAccountsSelect()}
                                    </Col>
                                    <Col className="small-col">
                                        {this.renderChaletsSelect()}
                                    </Col>
                                    <Col className="small-col">
                                        {this.renderCategoriesSelect()}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div style={{marginBottom: "8px", marginTop: "6px"}}>
                            {this.renderDatePreviousMonthButton()}
                            {this.renderDateCurrentMonthButton()}
                            {this.renderDateNextMonthButton()}
                            {this.renderDatePreviousYearButton()}
                            {this.renderDateCurrentYearButton()}
                            {this.renderDateNextYearButton()}
                        </div>
                        <div>
                            {this.renderInstallmentsSummary()}
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Accordion>
        )
    }
}

export default InstallmentsContentFilter;