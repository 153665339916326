import SideNav, {NavItem, NavIcon, NavText} from '@trendmicro/react-sidenav';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import "./sidenav.scss"
import Divider from "../navbar/divider/divider";
import {withRouter} from 'react-router-dom';
import LoginStorage from "../resources/loginStorage";

function handleSelect(selected, history, location) {
    if (selected === "logout") {
        logout(history);
        return;
    }

    const to = '/' + selected;
    if (location.pathname !== to) {
        history.push(to);
    }
}

function logout(history) {
    LoginStorage.logout();
    history.push("/");
}

const Sidenav = (props) => {
    const {history, location, onToggle} = props;

    return (
        <SideNav
            onSelect={(selected) => handleSelect(selected, history, location)}
            id={"sidenav-wrapper"}
            onToggle={onToggle}
        >
            <SideNav.Toggle disabled={false}/>
            <Divider/>
            <SideNav.Nav defaultSelected="expenses">

                <NavItem eventKey="expenses">
                    <NavIcon>
                        <FontAwesomeIcon size="lg" icon="money-bill-alt"/>
                    </NavIcon>
                    <NavText>
                        Despesas
                    </NavText>
                </NavItem>

                <NavItem eventKey="installments">
                    <NavIcon>
                        <FontAwesomeIcon size="lg" icon="list-alt"/>
                    </NavIcon>
                    <NavText>
                        Parcelas
                    </NavText>
                </NavItem>

                <NavItem eventKey="subscriptions">
                    <NavIcon>
                        <FontAwesomeIcon size="lg" icon="ticket-alt"/>
                    </NavIcon>
                    <NavText>
                        Assinaturas
                    </NavText>
                </NavItem>

                {/*<NavItem eventKey="clients">*/}
                {/*    <NavIcon>*/}
                {/*        <FontAwesomeIcon size="lg" icon="users"/>*/}
                {/*    </NavIcon>*/}
                {/*    <NavText>*/}
                {/*        Clientes*/}
                {/*    </NavText>*/}
                {/*</NavItem>*/}
                {/*<NavItem eventKey="orders">*/}
                {/*    <NavIcon>*/}
                {/*        <FontAwesomeIcon size="lg" icon="shopping-cart"/>*/}
                {/*    </NavIcon>*/}
                {/*    <NavText>*/}
                {/*        Pedidos*/}
                {/*    </NavText>*/}
                {/*</NavItem>*/}

                {/*<NavItem eventKey="deliveries">*/}
                {/*    <NavIcon>*/}
                {/*        <FontAwesomeIcon size="lg" icon="truck"/>*/}
                {/*    </NavIcon>*/}
                {/*    <NavText>*/}
                {/*        Entregas*/}
                {/*    </NavText>*/}
                {/*</NavItem>*/}

                {/*<Divider/>*/}

                {/*<NavItem eventKey="pix">*/}
                {/*    <NavIcon>*/}
                {/*        <FontAwesomeIcon size="lg" icon="money-check-alt"/>*/}
                {/*    </NavIcon>*/}
                {/*    <NavText>*/}
                {/*        Pix*/}
                {/*    </NavText>*/}
                {/*</NavItem>*/}

                <Divider/>

                <NavItem eventKey="logout">
                    <NavIcon>
                        <FontAwesomeIcon size="lg" icon="door-open"/>
                    </NavIcon>
                    <NavText>
                        Sair
                    </NavText>
                </NavItem>

            </SideNav.Nav>
        </SideNav>
    )
};

export default withRouter(Sidenav);