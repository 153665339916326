import {withRouter} from "react-router-dom";
import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Spinner} from "react-bootstrap";
import autoBind from "auto-bind";
import PixRemote from "../../resources/remote/pix-remote";
import PixTable from "./components/pix-table";
import _ from "lodash";
import alertify from "alertifyjs";


class PixContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pixKeys: [],
            isLoading: false,
        };

        autoBind(this);
    }

    componentDidMount() {
        this.setState({isLoading: true});
        PixRemote.list()
            .then((pixKeys) => this.setState({isLoading: false, pixKeys}));
    }

    handleOpenPageCreatePixKey() {
        const {history} = this.props;
        history.push("/pix/create");
    }

    handlePixKeyRemove(pixKey) {
        const removePixKey = () => {
            PixRemote.remove(pixKey.idPixKey)
                .then(response => {
                    const {pixKeys} = this.state;
                    pixKeys.splice(pixKeys.indexOf(pixKey), 1);
                    this.setState({pixKeys});
                    alertify.success("Chave Pix removida!");
                })
                .catch(error => {
                    alertify.error("Não foi possível remover a chave Pix.\n" + error);
                });
        }

        alertify.confirm(
            "Remover chave: " + pixKey.description,
            "A remoção da chave '" + pixKey.description + "' não poderá ser desfeita.",
            removePixKey,
            _.noop
        );
    }

    handlePixKeyEdit(pixKey) {
        const {history} = this.props;
        history.push(`/pix/update/${pixKey.idPixKey}`)
    }

    renderPixTable() {
        const {isLoading, pixKeys} = this.state;

        if (isLoading) {
            return this.renderLoadingComponent();
        }

        return (
            <PixTable
                pixKeys={pixKeys}
                onRemove={this.handlePixKeyRemove}
                onEdit={this.handlePixKeyEdit}
            />
        )
    }

    renderLoadingComponent() {
        return (
            <div style={{color: "grey", textAlign: "center"}}>
                <div><Spinner animation="border"/></div>
                <div>Carregando chaves Pix</div>
            </div>
        );
    }

    render() {
        return (
            <div>
                <div style={{display: "inline-block", width: "100%"}}>
                    <h3 className="float-left">Pix</h3>
                    <Button variant="primary" size="md" className="float-right"
                            onClick={this.handleOpenPageCreatePixKey}
                    >
                        <FontAwesomeIcon size="sm" icon="plus" style={{marginRight: "8px"}}/>
                        Adicionar chave
                    </Button>
                </div>
                {this.renderPixTable()}
            </div>
        )
    }
}

export default withRouter(PixContent)