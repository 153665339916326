import {urlBase, params, resolve} from "../resources";

const url = urlBase + "/api/expense";

export function list() {
    return fetch(url + "/list", params.get())
        .then(response => response.json())
        .then(resolve);
}

export function create(expense) {
    const content = {body: expense};
    return fetch(url, params.put(content))
        .then(response => response.json())
        .then(resolve);
}

export function update(expense) {
    const content = {body: expense};
    return fetch(url, params.post(content))
        .then(response => response.json())
        .then(resolve);
}

export function get(idProduct) {
    return fetch(url + "/" + idProduct, params.get())
        .then(response => response.json())
        .then(resolve);
}

export function remove(idProduct) {
    return fetch(url + "/" + idProduct, params.delete())
        .then(response => response.json())
        .then(resolve);
}

const ExpenseRemote = {
    list,
    create,
    update,
    get,
    remove
}

export default ExpenseRemote;