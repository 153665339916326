import React, {Component} from "react";
import {Route, withRouter} from "react-router-dom";
import {Accordion, Button, Card, Col, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProductsModalAddProduct from "./expenses-modal-new-expense";
import * as ExpenseRemote from "../../resources/remote/expense-remote";
import _ from "lodash";
import alertify from "alertifyjs";
import autoBind from "auto-bind";
import ExpensesModalNewExpense from "./expenses-modal-new-expense";
import ExpenseItem from "./expense-item/expense-item";
import ExpensesContentFilter from "./expenses-content-filter/expenses-content-filter";
import "./expenses-content.scss";

class ExpensesContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expenses: [],
            filteredExpenses: [],
            isLoading: false,
        };

        autoBind(this);
    }

    componentDidMount() {
        this.setState({isLoading: true});
        ExpenseRemote.list()
            .then((expenses) => {
                this.setState({isLoading: false, expenses});
            });
    }

    openNewExpenseModal() {
        const {history} = this.props;
        history.push('/expenses/add');
    }

    openEditExpenseModal(idExpense) {
        const {history} = this.props;
        history.push('/expenses/edit/' + idExpense);
    }

    handleCreateExpense(expense) {
        const {expenses} = this.state;
        const expensesCopy = _.cloneDeep(expenses);

        return ExpenseRemote.create(expense)
            .then((expense) => {
                expensesCopy.push(expense);
                this.setState({expenses: expensesCopy});
                alertify.success("Despesa adicionada!");
            })
            .catch(() => alertify.error("Erro ao adicionar despesa!"));
    }

    handleEditExpense(expense) {
        this.openEditExpenseModal(expense.idExpense);
    }

    handleUpdateExpense(expense) {
        const {expenses} = this.state;
        const expensesCopy = _.cloneDeep(expenses);
        Object.assign(_.find(expensesCopy, _.matchesProperty("idExpense", expense.idExpense)), expense);
        this.setState({expenses: expensesCopy});
    }

    handleRemoveExpense(expense) {
        const removeExpense = () => {
            ExpenseRemote.remove(expense.idExpense)
                .then((response) => {
                    const {expenses} = this.state;
                    const expensesCopy = _.cloneDeep(expenses);
                    _.remove(expensesCopy, (e) => e.idExpense === expense.idExpense)
                    this.setState({expenses: expensesCopy});
                    alertify.success("Despesa removida!");
                })
                .catch((error) => {
                    const message = "Não foi possível remover a despesa. " + error;
                    alertify.error(message);
                });
        };

        alertify.confirm(
            "Excluir despesa: " + expense.name,
            "A exclusão da despesa '" + expense.name + "' não poderá ser desfeita.",
            removeExpense,
            _.noop
        );
    }

    handleCloseNewExpenseModal() {
        const {history} = this.props;
        history.push('/expenses');
    }

    handleFilteredExpenses(filteredExpenses) {
        this.setState({filteredExpenses});
    }

    renderExpensesModalNewExpense() {
        const {expenses} = this.state;
        return (
            <Route
                path='/expenses/add'
                render={(props) => (
                    <ProductsModalAddProduct {...props}
                                             onCreate={this.handleCreateExpense}
                                             onClose={this.handleCloseNewExpenseModal}
                                             expenses={expenses}
                    />
                )}
            />
        );
    }

    renderExpensesModalEditExpense() {
        const {expenses} = this.state;
        return (
            <Route
                path='/expenses/edit/:idExpense'
                render={(props) => (
                    <ExpensesModalNewExpense {...props}
                                             onUpdate={this.handleUpdateExpense}
                                             onClose={this.handleCloseNewExpenseModal}
                                             expenses={expenses}
                                             isEditMode
                    />
                )}
            />
        );
    }

    // renderProductsTable() {
    //     const {isLoading, products} = this.state;
    //
    //     if (isLoading) {
    //         return this.renderLoadingComponent();
    //     }
    //
    //     return (
    //         <ProductsTable
    //             products={products}
    //             onRemove={this.handleRemoveProduct}
    //             onEdit={this.handleEditProduct}
    //         />
    //     );
    // }

    renderLoadingComponent() {
        return (
            <div style={{color: "grey", textAlign: "center"}}>
                <div><Spinner animation="border"/></div>
                <div>Carregando despesas</div>
            </div>
        );
    }

    renderExpenses() {
        const {isLoading, expenses} = this.state;
        const {filteredExpenses} = this.state;

        if (isLoading) {
            return this.renderLoadingComponent();
        }

        if (_.isEmpty(filteredExpenses)) {
            return (
                <div>Não há despesas disponíveis.</div>
            )
        }

        return _.orderBy(filteredExpenses, ['idExpense'], ['desc']).map(expense => {
            return (
                <div
                    id={"expenses-content-expense-item-" + expense.idExpense}
                    key={"expenses-content-expense-item-" + expense.idExpense}
                >
                    <ExpenseItem expense={expense} onRemove={this.handleRemoveExpense} onUpdate={this.handleUpdateExpense}></ExpenseItem>
                </div>
            )
        })
    }

    renderFilter() {
        const {expenses} = this.state;
        return (
            <ExpensesContentFilter expenses={expenses}
                                   onFilter={this.handleFilteredExpenses}
            />
        )
    }

    render() {
        return (
            <div>
                <div style={{display: "inline-block", width: "100%"}}>
                    <h3 className='float-left small-title'>Despesas</h3>
                    <Button variant={"primary"} size="md" className='float-right small-btn'
                            onClick={this.openNewExpenseModal}>
                        <FontAwesomeIcon size="sm" icon="plus" style={{marginRight: "8px"}}/>
                        Adicionar despesa
                    </Button>
                </div>
                {this.renderFilter()}
                {this.renderExpenses()}
                {this.renderExpensesModalNewExpense()}
                {/*{this.renderExpensesModalEditExpense()}*/}
            </div>
        );
    }

}

export default withRouter(ExpensesContent);