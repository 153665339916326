import React from "react";

const Checkbox = (props) => {
    return (
        <div className="icheck-primary">
            <input type="checkbox" id={props.id} onChange={props.onChange} checked={props.checked}/>
            <label htmlFor={props.id} className={props.className}>{props.label ? props.label : ''}</label>
        </div>
    );
}

export default Checkbox;