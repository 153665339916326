import {urlBase, params, resolve} from "../resources";

const url = urlBase + "/api/delivery";

function list() {
    return fetch(url + "/list", params.get())
        .then(response => {
            return response.json()
        })
        .then(resolve);
}

function create(delivery) {
    const content = {body: delivery}
    return fetch(url, params.put(content))
        .then(response => response.json())
        .then(resolve);
}

function update(delivery) {
    const content = {body: delivery};
    return fetch(url, params.post(content))
        .then(response => response.json())
        .then(resolve);
}

function get(idDelivery) {
    return fetch(url + "/" + idDelivery, params.get())
        .then(response => response.json())
        .then(resolve);
}

function getDeliveryOrders(idDelivery) {
    return fetch(`${url}/${idDelivery}/orders`, params.get())
        .then(response => response.json())
        .then(resolve);
}

function remove(idDelivery) {
    return fetch(url + "/" + idDelivery, params.delete())
        .then(response => response.json())
        .then(resolve);
}

function completeDelivery(idDelivery) {
    return fetch(`${url}/${idDelivery}/complete`, params.post())
        .then(response => response.json())
        .then(resolve);
}

const DeliveryRemote = {
    list,
    create,
    update,
    get,
    getDeliveryOrders,
    remove,
    completeDelivery
};

export default DeliveryRemote;