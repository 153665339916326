import "./orders-order.scss";
import React from "react";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Accordion, Card, Col, Row, Button} from "react-bootstrap";

const OrdersOrder = (props) => {
    const {order, onEdit, onRemove} = props;

    const displayDate = (date) => {
        return moment(date).format('DD/MM/YYYY');
    }

    const displayValue = (value) => {
        return `R$ ${parseFloat(String(value)).toFixed(2).replace(".", ",")}`;
    }

    const renderOrderNumber = () => {
        return (
            <div>
                <div className="subtitle">No.</div>
                <div className="title">{order.idNumber}</div>
            </div>
        )
    }

    const renderClientName = () => {
        return (
            <div>
                <div className="subtitle">Cliente</div>
                <div className="title">{order.client.name}</div>
            </div>
        );
    }

    const renderDate = () => {
        return (
            <div>
                <div className="subtitle">Data</div>
                <div className="title">{displayDate(order.date)}</div>
            </div>
        )
    }

    const renderStatus = () => {
        const isPaid = order.paidOnDate != null;
        const isDelivered = order.deliveredOnDate != null;

        return (
            <div>
                <div className="subtitle">Estado</div>
                <div>
                    <FontAwesomeIcon
                        style={{marginRight: "5px"}}
                        className={isPaid ? "OrdersOrder-active-icon" : "OrdersOrder-inative-icon"}
                        size="1x"
                        icon="hand-holding-usd"
                    />
                    <FontAwesomeIcon
                        className={isDelivered ? "OrdersOrder-active-icon" : "OrdersOrder-inative-icon"}
                        size="1x"
                        icon="truck-loading"
                    />
                </div>
            </div>
        );
    }

    const renderTotalValue = () => {
        return (
            <div>
                <div className="subtitle">Total</div>
                <div className="title">{displayValue(order.value)}</div>
            </div>
        )
    }

    const renderToggleIcon = () => {
        return (
            <div className="OrdersOrder-toggle-icon">
                <FontAwesomeIcon icon="angle-down" size="sm"/>
            </div>
        );
    }

    const renderOrderContent = () => {
        return;
    }

    return (
        <Accordion className="OrdersOrder">
            <Accordion.Toggle as={Card.Header} className="OrdersOrder-suppress-card-header OrdersOrder-toggle"
                              eventKey="0">
                <Row>
                    <Col md={1}>{renderOrderNumber()}</Col>
                    <Col md={1}>{renderStatus()}</Col>
                    <Col md={6}>{renderClientName()}</Col>
                    <Col md={2}>{renderDate()}</Col>
                    <Col md={2}>{renderTotalValue()}</Col>
                </Row>
                {renderToggleIcon()}

            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    {renderOrderContent()}
                </Card.Body>
            </Accordion.Collapse>
        </Accordion>
    );
};

export default OrdersOrder;