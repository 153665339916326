import _ from 'lodash';
import moment from "moment";
import "./delivery-item.scss";
import autoBind from "auto-bind";
import React, {Component} from "react";
import {Accordion, Card, Col, Row} from "react-bootstrap";
import Checkbox from "../../../../utils/checkbox/checkbox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DeliveryItemContent from "../delivery-item-content/delivery-item-content";


class DeliveryItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAccordionOpen: false,
        };

        autoBind(this);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {delivery} = this.props;

        return !_.isEqual(delivery, nextState.delivery);
    }

    displayDate(date) {
        return moment(date).format('DD/MM/YYYY');
    }

    displayValue(value) {
        return `R$ ${parseFloat(String(value)).toFixed(2).replace(".", ",")}`;
    }

    handleAccordionToggle() {
        const {isAccordionOpen} = this.state;
        this.setState({isAccordionOpen: !isAccordionOpen});
    }

    renderDeliveryNumber() {
        const {delivery} = this.props;

        return (
            <div>
                <div className="subtitle">No.</div>
                <div className="title">{delivery.idNumber}</div>
            </div>
        );
    }

    renderStatus() {
        const {delivery} = this.props;

        let iconClass;
        switch (delivery.status) {
            case "CANCELED":
                iconClass = "DeliveryItem-canceled-icon";
                break;
            case "DELIVERED":
                iconClass = "DeliveryItem-active-icon";
                break;
            case "NOT_DELIVERED":
            default:
                iconClass = "DeliveryItem-inactive-icon";
                break;
        }

        return (
            <div>
                <div className="subtitle">Estado</div>
                <FontAwesomeIcon
                    className={iconClass}
                    size="1x"
                    icon="truck-loading"
                />
            </div>
        );
    }

    renderExpectedDeliveryDate() {
        const {delivery} = this.props;

        return (
            <div>
                <div className="subtitle">Entrega agendada para</div>
                <div className="title">{this.displayDate(delivery.expectedDeliveryDate)}</div>
            </div>
        );
    }

    renderNumberOfDeliveryOrders() {
        const {delivery} = this.props;

        const numberOfOrders = _.get(delivery, "deliveryInformation.numberOfOrders", 0);

        return (
            <div>
                <div className="subtitle">Pedidos</div>
                <div className="title">{numberOfOrders}</div>
            </div>
        );
    }

    renderTotalValueOrders() {
        const {delivery} = this.props;

        const totalValue = _.get(delivery, "deliveryInformation.totalValue", 0);

        return (
            <div>
                <div className="subtitle">Valor total</div>
                <div className="title">{this.displayValue(totalValue)}</div>
            </div>
        );
    }

    renderToggleIcon() {
        return (
            <div className="OrdersOrder-toggle-icon">
                <FontAwesomeIcon icon="angle-down" size="sm"/>
            </div>
        );
    }

    renderDeliveryContent() {
        const {delivery, onRemove, onEdit, onCompleteDelivery} = this.props;
        const {isAccordionOpen} = this.state;

        return (
            <DeliveryItemContent delivery={delivery} isAccordionOpen={isAccordionOpen}
                                 onRemove={onRemove}
                                 onEdit={onEdit}
                                 onCompleteDelivery={onCompleteDelivery}/>
        );
    }

    render() {
        return (
            <div>
                <div style={{display: 'flex', alignItems: 'center', margin: '0'}}>
                    <Accordion className="DeliveryItem" style={{width: '100%'}}>
                        <Accordion.Toggle as={Card.Header}
                                          className="DeliveryItem-suppress-card-header DeliveryItem-toggle"
                                          eventKey="0" onClick={this.handleAccordionToggle}>
                            <Row>
                                <Col md={1}>{this.renderDeliveryNumber()}</Col>
                                <Col md={2}>{this.renderStatus()}</Col>
                                <Col md={2}>{this.renderExpectedDeliveryDate()}</Col>
                                <Col md={2}>{this.renderNumberOfDeliveryOrders()}</Col>
                                <Col md={2}>{this.renderTotalValueOrders()}</Col>
                            </Row>
                            {this.renderToggleIcon()}
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                {this.renderDeliveryContent()}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Accordion>
                </div>
            </div>
        );
    }
}

export default DeliveryItem;