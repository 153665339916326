import {urlBase, params, resolve} from "../resources";

const url = urlBase + "/api/client";

function list() {
    return fetch(url + "/list", params.get())
        .then(response => response.json())
        .then(resolve);
}

function create(client) {
    const content = {body: client};
    return fetch(url, params.put(content))
        .then(response => response.json())
        .then(resolve);
}

function update(client) {
    const content = {body: client};
    return fetch(url, params.post(content))
        .then(response => response.json())
        .then(resolve);
}

function get(idClient) {
    return fetch(url + "/" + idClient, params.get())
        .then(response => response.json())
        .then(resolve);
}

function remove(idClient) {
    return fetch(url + "/" + idClient, params.delete())
        .then(response => response.json())
        .then(resolve);
}

const ClientsRemote = {
    list,
    create,
    update,
    get,
    remove
};

export default ClientsRemote;