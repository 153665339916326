import "./register.scss"
import {Button, Form, InputGroup, Spinner} from "react-bootstrap";
import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from "lodash";
import autoBind from "auto-bind";
import {Link, withRouter} from "react-router-dom";
import * as UserRemote from "../resources/remote/user-remote";
import alertify from "alertifyjs";

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submited: false,
            validated: {
                name: false,
                email: false,
                password: false,
                confirmPassword: false,
            },
            actions: {
                showPassword: false,
                showConfirmPassword: false,
            },
            isRegistering: false,
            name: null,
            email: null,
            password: null,
            confirmPassword: null
        };

        autoBind(this);
    }

    isFormValidated() {
        const {validated} = this.state;
        return Object.values(validated).reduce((result, value) => result && value);
    }

    validateName(event) {
        const {validated} = this.state;
        const name = event.target.value;
        validated.name = name.length >= 3 && name.length <= 100;
        this.setState({validated, name});
    }

    validatedEmail(event) {
        const {validated} = this.state;
        const re = /\S+@\S+\.\S+/;
        const email = event.target.value;
        validated.email = re.test(email);
        this.setState({validated, email});
    }

    validatePassword(event) {
        const {validated, confirmPassword} = this.state;
        const password = event.target.value;
        validated.password = password.length >= 8 && password.length <= 100;
        validated.confirmPassword = _.isEqual(password, confirmPassword);
        this.setState({validated, password});
    }

    validateConfirmPassword(event) {
        const {validated, password} = this.state;
        const confirmPassword = event.target.value;
        validated.confirmPassword = _.isEqual(password, confirmPassword);
        this.setState({validated, confirmPassword});
    }

    handleSubmit(event) {
        const {name, email, password, isRegistering} = this.state;
        const {history} = this.props;

        event.preventDefault();
        event.stopPropagation();

        this.setState({submited: true});

        if (!this.isFormValidated() || isRegistering) {
            return;
        }

        this.setState({isRegistering: true});

        const user = {
            name,
            email,
            password
        };

        return UserRemote.create(user)
            .then(() => {
                this.setState({isRegistering: false});
                history.push("/");
                alertify.success("Usuário cadastrado com sucesso!");
            })
            .catch((error) => {
                this.setState({isRegistering: false});
                alertify.error("Erro ao cadastrar usuário!\n" + error);
            })
    }

    handleShowPassword() {
        const {actions} = this.state;
        actions.showPassword = !actions.showPassword;
        this.setState({actions});
    }

    handleShowConfirmPassword() {
        const {actions} = this.state;
        actions.showConfirmPassword = !actions.showConfirmPassword;
        this.setState({actions});
    }

    renderFieldName() {
        const {submited, validated, isRegistering} = this.state;
        return (
            <Form.Group>
                <Form.Label>
                    Nome
                </Form.Label>
                <Form.Control
                    type="text"
                    required
                    name="name"
                    isInvalid={submited && !validated.name}
                    isValid={submited && validated.name}
                    onChange={this.validateName}
                    disabled={isRegistering}
                />
                <Form.Control.Feedback type="invalid">O nome deve ter entre 3 e 100 caracteres!</Form.Control.Feedback>
            </Form.Group>
        );
    }

    renderFieldEmail() {
        const {submited, validated, isRegistering} = this.state;
        return (
            <Form.Group>
                <Form.Label>
                    E-mail
                </Form.Label>
                <Form.Control
                    type="email"
                    required
                    isInvalid={submited && !validated.email}
                    isValid={submited && validated.email}
                    onChange={this.validatedEmail}
                    disabled={isRegistering}
                />
                <Form.Control.Feedback type="invalid">Por favor, insira um e-mail válido!</Form.Control.Feedback>
            </Form.Group>
        );
    }

    renderFieldPassword() {
        const {submited, validated, actions, isRegistering} = this.state;
        return (
            <Form.Group>
                <Form.Label>
                    Senha
                </Form.Label>
                <InputGroup>
                    <Form.Control
                        type={actions.showPassword ? "text" : "password"}
                        required
                        isInvalid={submited && !validated.password}
                        isValid={submited && validated.password}
                        onChange={this.validatePassword}
                        disabled={isRegistering}
                    />
                    <InputGroup.Append>
                        <Button variant="outline-dark" onClick={this.handleShowPassword} disabled={isRegistering}>
                            <FontAwesomeIcon size="sm" icon={actions.showPassword ? "eye-slash" : "eye"}/>
                        </Button>
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">
                        A senha deve ter no mínimo 8 e no máximo 100 caracteres!
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>
        );
    }

    renderFieldConfirmPassword() {
        const {submited, validated, actions, isRegistering} = this.state;
        return (
            <Form.Group>
                <Form.Label>
                    Confirmar senha
                </Form.Label>
                <InputGroup>
                    <Form.Control
                        type={actions.showConfirmPassword ? "text" : "password"}
                        required
                        isInvalid={submited && !validated.confirmPassword}
                        isValid={submited && validated.confirmPassword}
                        onChange={this.validateConfirmPassword}
                        disabled={isRegistering}
                    />
                    <InputGroup.Append>
                        <Button variant="outline-dark" onClick={this.handleShowConfirmPassword}
                                disabled={isRegistering}>
                            <FontAwesomeIcon size="sm" icon={actions.showConfirmPassword ? "eye-slash" : "eye"}/>
                        </Button>
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">
                        As senhas devem ser iguais!
                    </Form.Control.Feedback>

                </InputGroup>
            </Form.Group>
        );
    }

    renderButtonRegister() {
        const {isRegistering} = this.state;

        return (
            <Button className="Login-login-button btn btn-success" variant="success" type="submit"
                    disabled={isRegistering}>
                {isRegistering ?
                    <div>
                        <Spinner animation="border" role="status" size="sm" className="mr-2"/>
                        Cadastrando
                    </div> : <div>Cadastrar</div>
                }
            </Button>
        )
    }

    renderLinkLogin() {
        return (
            <Link to="/">
                Já possui uma conta? Faça o login!
            </Link>
        )
    }

    render() {
        return (
            <Form
                noValidate
                className="Register-form"
                onSubmit={this.handleSubmit}
            >
                {this.renderFieldName()}
                {this.renderFieldEmail()}
                {this.renderFieldPassword()}
                {this.renderFieldConfirmPassword()}
                {this.renderButtonRegister()}
                {this.renderLinkLogin()}
            </Form>
        );
    }
}

export default withRouter(Register);