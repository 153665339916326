import {urlBase, params, resolve} from "../resources";

const url = urlBase + "/api/order";

function list() {
    return fetch(url + "/list", params.get())
        .then(response => response.json())
        .then(resolve);
}

function create(order) {
    const content = {body: order};
    return fetch(url, params.put(content))
        .then(response => response.json())
        .then(resolve);
}

function update(order) {
    const content = {body: order};
    return fetch(url, params.post(content))
        .then(response => response.json())
        .then(resolve);
}

function get(idOrder) {
    return fetch(url + "/" + idOrder, params.get())
        .then(response => response.json())
        .then(resolve);
}

function remove(idOrder) {
    return fetch(url + "/" + idOrder, params.delete())
        .then(response => response.json())
        .then(resolve);
}

const OrderRemote = {
    list,
    create,
    update,
    get,
    remove
};

export default OrderRemote;