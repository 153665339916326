import React, {Component} from "react";
import autoBind from "auto-bind";
import {Button, Form, InputGroup, Modal, Spinner} from "react-bootstrap";
import _ from "lodash";


class StocksOperationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: 0,
            isSaving: false
        };

        this._stock = null;
        this._amountInputRef = null;

        autoBind(this);
    }

    componentWillMount() {
        const {match, stocks} = this.props;
        const idStock = parseInt(match.params.idStock);
        this._stock = _.find(stocks, _.matchesProperty("idStock", idStock));
    }

    componentDidMount() {
        this._amountInputRef.focus();
    }

    handleAmountChange(event) {
        const amount = parseFloat(event.target.value);
        this.setState({amount});
    }

    handleSubmit(event) {
        const {onSave, onClose} = this.props;
        const {amount} = this.state;
        const idStock = this._stock.idStock;

        event.preventDefault();
        event.stopPropagation();

        this.setState({isSaving: true});

        onSave(idStock, amount)
            .then(() => {
                this.setState({isSaving: false});
                onClose();
            });
    }

    render() {
        const {amount, isSaving} = this.state;
        const {isAddMode, onClose} = this.props;

        return (
            <Modal show size="sm" backdrop="static">
                <Modal.Header>
                    <Modal.Title>
                        {isAddMode ? "Adicionar" : "Subtrair"} quantidade
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form id="stocks-operation-form" onSubmit={this.handleSubmit}>
                        <Form.Group>
                            <Form.Label>Quantidade</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    ref={(input) => {this._amountInputRef = input;}}
                                    type="number"
                                    required
                                    step="0.01"
                                    min={0}
                                    max={isAddMode ? undefined : this._stock.amount}
                                    focused
                                    placeholder={0}
                                    onChange={this.handleAmountChange}
                                />
                                <InputGroup.Append>
                                    <InputGroup.Text>{this._stock.product.measurementUnit}</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="mr-auto"
                            onClick={onClose}
                            variant="light"
                    >
                        Cancelar
                    </Button>
                    <button className="btn btn-success" type="submit" form="stocks-operation-form"
                            disabled={isSaving || amount === 0}>
                        {isSaving ?
                            <div>
                                <Spinner animation="border" role="status" size="sm" className="mr-2"/>
                                Salvando
                            </div> : <div>Salvar</div>
                        }
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default StocksOperationModal;