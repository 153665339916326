import { QrCodePix } from 'qrcode-pix';

const generate = (receiverName, key, description, amount) => {
    return QrCodePix({
        version: '01',
        key: key,
        name: receiverName,
        city: 'Bom Retiro',
        message: description,
        value: amount
    }).payload()
}

const QRCode = {
    generate
};

export default QRCode;