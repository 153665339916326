export const Categories = {
    HOME_APPLIANCES: "Eletrodomésticos",
    MAINTENANCE: "Manutenção",
    CLEANING: "Limpeza",
    ATTRACTIONS: "Atrativos",
    MARKETING: "Marketing",
    CONSTRUCTION_MATERIALS: "Construção",
    LIGHTING: "Iluminação",
    DECORATION: "Decoração",
    FURNITURE: "Móveis",
    KITCHEN: "Cozinha",
    BATHROOM: "Banheiro",
    BEDROOM: "Quarto",
    ELECTRICITY: "Energia elétrica",
    INVESTMENT: "Investimento",
    LOAN: "Empréstimo",
    OTHERS: "Outros"
}

export const Chalets = {
    CABIN_1: "Cabana 1",
    CABIN_2: "Cabana 2",
    CABIN_3: "Cabana 3",
    SHARED: "Compartilhado"
}

export const Accounts = {
    DOCE_RECANTO: "Doce Recanto",
    LUKAS: "Lukas",
    NIKOLAS: "Níkolas",
    CHARLES: "Charles",
    FABIANA: "Fabiana",
}

export const InstallmentFrequency = {
    MONTHLY: "Mensal",
    QUARTERLY: "Trimestral",
    YEARLY: "Anual"
}

export const ExpenseType = {
    REGULAR: "Compra única",
    IN_INSTALLMENTS: "Parcelado",
    SUBSCRIPTION: "Assinatura"
}