import './App.css';
import Content from "./pages/content/content";
import Sidenav from "./sidenav/sidenav";
import {Component} from "react";
import {withRouter} from "react-router-dom";
import Login from "./login/login";
import LoginStorage from "./resources/loginStorage";
import autoBind from "auto-bind";

class App extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isSidenavExpanded: false,
        };

        autoBind(this);
    }

    handleSidenavToggle() {
        const {isSidenavExpanded} = this.state;
        this.setState({isSidenavExpanded: !isSidenavExpanded});
    }

    handleLogin(login) {
        if (login == null) {
            LoginStorage.logout();
        } else {
            LoginStorage.login(login);
        }
    }

    isLogged() {
        return LoginStorage.isLogged();
    }

    renderLogin() {
        const {history, location} = this.props;
        if (location.pathname !== "/" && location.pathname !== "/register") {
            history.push("/");
        }

        return (
            <Login onLogin={this.handleLogin}/>
        )
    }

    renderApplication() {
        const {isSidenavExpanded} = this.state;
        return (
            <div>
                <Sidenav style={{"position": "relative", "flex": "auto"}} onToggle={() => this.handleSidenavToggle()} onSelect={() => {}}/>
                <Content isSidenavExpanded={isSidenavExpanded}/>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.isLogged() ? this.renderApplication() : this.renderLogin()}
            </div>
        );
    }

}

export default withRouter(App);
