import {urlBase, params, resolve} from "../resources";

const url = urlBase + "/api/installment";

export function list() {
    return fetch(url + "/list", params.get())
        .then(response => response.json())
        .then(resolve);
}

export function create(installment) {
    const content = {body: installment};
    return fetch(url, params.put(content))
        .then(response => response.json())
        .then(resolve);
}

export function update(expense, installment) {
    const content = {body: {expense, installment}};
    return fetch(url, params.post(content))
        .then(response => response.json())
        .then(resolve);
}

export function pay(installments) {
    const content = {body: {installments}};
    return fetch(`${url}/pay`, params.post(content))
        .then(response => response.json())
        .then(resolve);
}

const InstallmentRemote = {
    list,
    create,
    update,
    pay
};

export default InstallmentRemote;