export function getToken() {
    return localStorage.getItem("token");
}

export function getDuration() {
    return localStorage.getItem("duration");
}

export function getTimestamp() {
    return localStorage.getItem("timestamp");
}

export function setToken(token) {
    localStorage.setItem("token", token);
}

export function setDuration(duration) {
    localStorage.setItem("duration", duration);
}

export function setTimestamp(timestamp) {
    localStorage.setItem("timestamp", timestamp);
}

export function clear() {
    localStorage.clear();
}