import _ from "lodash";
import autoBind from "auto-bind";
import alertify from "alertifyjs";
import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {Button, Spinner} from "react-bootstrap";
import OrderRemote from "../../resources/remote/order-remote";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import OrdersOrder from "./components/orders-order/orders-order";


class OrdersContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            isLoading: false,
        };

        autoBind(this);
    }

    componentDidMount() {
        this.setState({isLoading: true});
        OrderRemote.list()
            .then((orders) => {
                const calculatedOrders = orders.map(order => {
                    order.value = order.orderItems.map(item => item.amount * item.product.price)
                        .reduce((totalValue, itemValue) => totalValue + itemValue, 0);
                    return order;
                });
                this.setState({orders: calculatedOrders, isLoading: false});
            })
            .catch((error) => {
                const message = "Erro ao buscar pedidos. " + error;
                alertify.error(message);
            });
    }

    handleOpenPageCreateOrder() {
        const {history} = this.props;
        history.push("/orders/create");
    }

    handleClosePageCreateOrder() {
        const {history} = this.props;
        history.push("/orders");
    }

    handleOrderRemove(order) {
        const {orders} = this.state;
        const removeOrder = () => {
            OrderRemote.remove(order.idOrder)
                .then(() => {
                    _.remove(orders, order);
                    this.setState({orders});
                    alertify.success("Pedido apagado!");
                })
                .catch(() => alertify.error("Erro ao apagar pedido!"));
        }

        alertify.confirm(
            "Apagar pedido: #" + order.idNumber,
            "A exclusão do pedido #" + order.idNumber + " não poderá ser desfeita.",
            removeOrder,
            _.noop
        );
    }

    handleOrderEdit(order) {
        const {history} = this.props;
        history.push(`/orders/update/${order.idOrder}`);
    }

    renderOrders() {
        const {isLoading, orders} = this.state;

        if (isLoading) {
            return this.renderLoadingComponent();
        }

        if (_.isEmpty(orders)) {
            return (
                <div>
                    Não há pedidos em aberto.
                </div>
            )
        }

        return _.orderBy(orders, ['idNumber'], ['desc']).map(order => {
            return (
                <div
                    id={"orders-content-order-" + order.idOrder}
                    key={"orders-content-order-" + order.idOrder}
                >
                    <OrdersOrder order={order} onRemove={this.handleOrderRemove} onEdit={this.handleOrderEdit}/>
                </div>
            );
        });
    }

    renderLoadingComponent() {
        return (
            <div style={{color: "grey", textAlign: "center"}}>
                <div><Spinner animation="border"/></div>
                <div>Carregando pedidos</div>
            </div>
        );
    }

    render() {
        return (
            <div>
                <div style={{display: "inline-block", width: "100%"}}>
                    <h3 className='float-left'>Pedidos</h3>
                    <Button variant={"primary"} size="md" className='float-right'
                            onClick={this.handleOpenPageCreateOrder}>
                        <FontAwesomeIcon size="sm" icon="plus" style={{marginRight: "8px"}}/>
                        Adicionar pedido
                    </Button>
                </div>
                {this.renderOrders()}
            </div>
        );
    }
}

export default withRouter(OrdersContent);