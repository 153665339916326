import React, {Component} from "react";
import autoBind from "auto-bind";
import ClientsRemote from "../../../resources/remote/clients-remote";
import * as ProductsRemote from "../../../resources/remote/expense-remote";
import alertify from "alertifyjs";
import {Button, Col, Form, InputGroup, Row, Spinner} from "react-bootstrap";
import SelectWrapper from "../../../utils/select-wrapper/select-wrapper";
import _ from "lodash";
import OrdersOrderStatus from "../components/orders-order-status/orders-order-status";
import OrdersCreateOrderAddProduct from "../components/order-create-order-add-product/orders-create-order-add-product";
import OrdersOrderItems from "../components/orders-order-items/orders-order-items";
import {withRouter} from "react-router-dom";
import OrderRemote from "../../../resources/remote/order-remote";
import "./orders-create-order.scss";


class OrdersCreateOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSaving: false,
            isLoading: false,

            clients: [],
            products: [],
            idOrder: null,
            idNumber: null,
            date: null,
            deliveryAddress: null,
            orderItems: [],
            client: null,
            note: null,

            deliveryAddressOptions: [],
        };

        this._isEditMode = false;
        autoBind(this);
    }

    async componentWillMount() {
        const {match} = this.props;
        const idOrder = match.params.idOrder;

        if (!_.isNil(idOrder)) {
            const order = await OrderRemote.get(idOrder);
            this._isEditMode = true;
            this.setState({...order});
        }
    }

    componentDidMount() {
        this.fetchClients();
        this.fetchProducts();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.props, nextProps)
            || !_.isEqual(this.state, nextState);
    }

    fetchClients() {
        ClientsRemote.list()
            .then(clients => this.setState({clients}))
            .catch(() => alertify.error("Erro ao buscar clientes!"));
    }

    fetchProducts() {
        this.setState({isLoading: true});
        ProductsRemote.list()
            .then(products => this.setState({products}))
            .catch(() => alertify.error("Erro ao buscar produtos!"))
            .finally(() => this.setState({isLoading: false}));
    }

    formatDeliveryAddressOption(client, deliveryAddress) {
        if (client == null || deliveryAddress == null) {
            return undefined;
        }

        return {
            value: deliveryAddress.description,
            label: deliveryAddress.description,
            subtitle: this.getClientSelectOptionSubtitle(client)
        };
    }

    getDeliveryAddressOptions(client, deliveryAddresses) {
        return deliveryAddresses.map((deliveryAddress) => this.formatDeliveryAddressOption(client, deliveryAddress));
    }

    getClientSelectOptionSubtitle(client) {
        const address = _.get(client, "deliveryAddresses[0]");

        if (address == null) {
            return null;
        }

        return address.street + ", " + address.neighborhood + " - " + address.city + ", " + address.state;
    }

    getClientSelectOptions() {
        const {clients} = this.state;
        return _.orderBy(clients, ["name"], ["asc"])
            .map(this.mapClientOption)
    }

    mapClientOption(client) {
        return {
            value: client.idClient,
            label: client.name,
            subtitle: this.getClientSelectOptionSubtitle(client)
        };
    }

    getOrderTotalValue() {
        const {orderItems} = this.state;

        if (_.isEmpty(orderItems)) {
            return 0;
        }

        return orderItems.reduce((total, orderItem) => total + (orderItem.product.price * orderItem.amount), 0);
    }

    closePage() {
        const {history} = this.props;
        history.push("/orders");
    }

    handleSubmit(event) {
        const {actions, client, date, idOrder, idNumber, orderItems, deliveryAddress, note} = this.state;

        event.preventDefault();
        event.stopPropagation();

        const order = {idOrder, idNumber, client, date, orderItems, deliveryAddress, note};
        this.setState({isSaving: true});

        const save = this._isEditMode ? OrderRemote.update : OrderRemote.create;
        save(order)
            .then((order) => {
                this.closePage();
                alertify.success(this._isEditMode ? "Pedido atualizado!" : "Pedido adicionado!");
            })
            .catch((error) => {
                alertify.error("Não foi possível adicionar pedido. " + error);
            })
            .finally(() => {
                this.setState({isSaving: false});
            });
    }

    handleOrderItemAdd(orderItem) {
        const orderItems = _.cloneDeep(this.state.orderItems);
        orderItems.push(orderItem);
        this.setState({orderItems});
    }

    handleOrderItemRemove(index) {
        const orderItems = _.cloneDeep(this.state.orderItems);
        orderItems.splice(index, 1);
        this.setState({orderItems});
    }

    handleClientSelectChange(event) {
        const {clients} = this.state;
        const idClient = event.value;
        const client = _.find(clients, _.matchesProperty("idClient", idClient));

        const deliveryAddresses = _.get(client, "deliveryAddresses", []);
        const deliveryAddressOptions = this.getDeliveryAddressOptions(client, deliveryAddresses);

        this.setState({client, deliveryAddressOptions, deliveryAddress: null});
    }

    handleNoteChange(event) {
        const note = event.target.value;
        this.setState({note});
    }

    handleDeliveryAddressChange(event) {
        const {client} = this.state;

        if (event == null) {
            return;
        }

        const description = event.value;
        const deliveryAddresses = _.get(client, "deliveryAddresses", []);

        const deliveryAddress = _.find(deliveryAddresses, _.matchesProperty("description", description));
        this.setState({deliveryAddress});
    }

    renderClientField() {
        const {client} = this.state;
        const value = client != null ? this.mapClientOption(client) : null;
        return (
            <Form.Group>
                <Form.Label>Cliente</Form.Label>
                <SelectWrapper
                    isSearchable
                    options={this.getClientSelectOptions()}
                    placeholder="Selecione uma opção"
                    required
                    onChange={this.handleClientSelectChange}
                    value={value}
                />
            </Form.Group>
        );
    }

    renderDeliveryAddressField() {
        const {client, deliveryAddress, deliveryAddressOptions} = this.state;

        let value;
        if (deliveryAddressOptions.length === 1) {
            value = deliveryAddressOptions[0];
            const deliveryAddresses = _.get(client, "deliveryAddresses", []);
            this.setState({deliveryAddress: deliveryAddresses[0]});
        } else {
            value = this.formatDeliveryAddressOption(client, deliveryAddress)
        }

        return (
            <Form.Group>
                <Form.Label>Endereço de entrega</Form.Label>
                <SelectWrapper
                    isSearchable
                    options={deliveryAddressOptions}
                    placeholder="Selecione um endereço"
                    required
                    value={value}
                    onChange={this.handleDeliveryAddressChange}
                />
            </Form.Group>
        )
    };

    renderNoteField() {
        const {note} = this.state;
        return (
            <Form.Group>
                <Form.Label>Anotações</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={this.handleNoteChange}
                    value={note}
                />
            </Form.Group>
        );
    }

    renderOrderStatus() {
        return (
            <div style={{marginTop: "56px"}}>
                <OrdersOrderStatus totalValue={this.getOrderTotalValue()}/>
            </div>
        );
    }

    renderOrderItems() {
        const {orderItems} = this.state;
        return (
            <div className="OrdersCreateOrder-order-items">
                <OrdersOrderItems orderItems={orderItems} onRemove={this.handleOrderItemRemove}/>
            </div>
        );
    }

    renderAddProductSection() {
        const {products, isLoading} = this.state;
        return (
            <OrdersCreateOrderAddProduct products={products} onAdd={this.handleOrderItemAdd} isLoading={isLoading}/>
        );
    }

    renderCloseButton() {
        const {isSaving} = this.state;
        return (
            <Button className="float-left" variant="outline-secondary" disabled={isSaving}
                    onClick={this.closePage}>
                Voltar
            </Button>
        );
    }

    renderSaveButton() {
        const {isSaving, client, deliveryAddress} = this.state;
        const disabled = client == null || deliveryAddress == null || isSaving;

        return (
            <Button className="float-right" variant="success" type="submit" disabled={disabled}>
                {isSaving ?
                    <div>
                        <Spinner animation="border" role="status" size="sm" className="mr-2"/>
                        Salvando
                    </div> : <div>Salvar</div>
                }
            </Button>
        );
    }

    render() {
        return (
            <div style={{height: "100%"}}>
                <div style={{display: "inline-block", width: "100%"}}>
                    <h3 className='float-left'>{this._isEditMode ? "Editar" : "Adicionar"} pedido</h3>
                </div>

                <Row style={{height: "calc(100vh - 60px)"}}>
                    <Col md={5} style={{position: "relative"}}>
                        {this.renderAddProductSection()}
                        <Form onSubmit={this.handleSubmit}>
                            {this.renderClientField()}
                            {this.renderDeliveryAddressField()}
                            {this.renderNoteField()}
                            <div style={{
                                position: "absolute",
                                bottom: 0,
                                width: "100%",
                                margin: "16px 16px 16px 0",
                                paddingRight: "30px"
                            }}>
                                {this.renderOrderStatus()}
                                {this.renderCloseButton()}
                                {this.renderSaveButton()}
                            </div>
                        </Form>
                    </Col>
                    <Col md={7}>
                        {this.renderOrderItems()}
                    </Col>
                </Row>

            </div>
        );
    }
}

export default withRouter(OrdersCreateOrder);