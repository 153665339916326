import "./select-option-layout.scss";
import {components} from "react-select";

const SelectOptionLayout = (props) => {
    const {data, isSelected} = props;

    const renderSubtitle = () => {
        if (data.subtitle == null) {
            return null;
        }

        return (
            <div className={isSelected ? "selected" : "not-selected"}>{props.data.subtitle}</div>
        );
    }

    return (
        <components.Option {...props}>
            <div>{props.data.label}</div>
            {renderSubtitle()}
        </components.Option>
    );
};

export default SelectOptionLayout;