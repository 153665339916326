import React, {Component} from "react";
import {Accordion, Button, Card, Col, Form, InputGroup, Row} from "react-bootstrap";
import DatePickerWrapper from "../../../utils/datepicker/datepicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Checkbox from "../../../utils/checkbox/checkbox";
import {Accounts, Categories, Chalets} from "../../../api/expenses";
import _ from "lodash";
import SelectWrapper from "../../../utils/select-wrapper/select-wrapper";
import autoBind from "auto-bind";
import moment from 'moment';
import "./expenses-content-filter.scss";

class ExpensesContentFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: null,
            startDate: null,
            endDate: null,
            isPaid: true,
            isNotPaid: true,
            accounts: [],
            chalets: [],
            categories: [],

            filteredExpenses: []
        };

        autoBind(this);
    }

    componentDidMount() {
        this.handleFilterChange();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.handleFilterChange();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.props, nextProps)
            || !_.isEqual(this.state, nextState);
    }

    getAccountsOptions() {
        const options = Object.entries(Accounts).map(([key, value]) => {
            return {"value": key, "label": value}
        });
        return _.orderBy(options, ["label"], ["asc"]);
    }

    getCategoriesOptions() {
        const options = Object.entries(Categories).map(([key, value]) => {
            return {"value": key, "label": value}
        });
        return _.orderBy(options, ["label"], ["asc"]);
    }

    getChaletOptions() {
        const options = Object.entries(Chalets).map(([key, value]) => {
            return {"value": key, "label": value}
        });
        return _.orderBy(options, ["label"], ["asc"]);
    }

    getFirstDateOfCurrentMonth() {
        return moment().startOf('month').toDate();
    }

    getLastDateOfCurrentMonth() {
        return moment().endOf('month').toDate();
    }

    getFirstDateOfPreviousMonth() {
        return moment().subtract(1, 'months').startOf('month').toDate();
    }

    getLastDateOfPreviousMonth() {
        return moment().subtract(1, 'months').endOf('month').toDate();
    }

    getFirstDateOfCurrentYear() {
        return moment().startOf('year').toDate();
    }

    getLastDateOfCurrentYear() {
        return moment().endOf('year').toDate();
    }

    handleExpenseNameChange(event) {
        const name = _.get(event, "target.value");
        this.setState({name});
    }

    handleStartDateChange(event) {
        const startDate = event;
        this.setState({startDate});
        // this.handleFilterChange();
    }

    handleEndDateChange(event) {
        const endDate = event;
        this.setState({endDate});
        // this.handleFilterChange();
    }

    handlePaidCheckBoxChange() {
        const {isPaid} = this.state;
        this.setState({isPaid: !isPaid});
        // this.handleFilterChange();
    }

    handleNotPaidCheckBoxChange() {
        const {isNotPaid} = this.state;
        this.setState({isNotPaid: !isNotPaid});
        // this.handleFilterChange();
    }

    handleCategoriesSelectChange(event) {
        if (event == null) {
            return null;
        }

        const categories = event;
        this.setState({categories});
        // this.handleFilterChange();
    }

    handleAccountsSelectChange(event) {
        if (event == null) {
            return null;
        }

        const accounts = event;
        this.setState({accounts});
        // this.handleFilterChange();
    }

    handleChaletsSelectChange(event) {
        if (event == null) {
            return null;
        }

        const chalets = event;
        this.setState({chalets});
        // this.handleFilterChange();
    }

    handleAccountsSelectAuxiliarButtonClick() {
        const accounts = this.getAccountsOptions()
        this.setState({accounts});
        // this.handleFilterChange();
    }

    handleChaletsSelectAuxiliarButtonClick() {
        const chalets = this.getChaletOptions()
        this.setState({chalets});
        // this.handleFilterChange();
    }

    handleCategoriesSelectAuxiliarButtonClick() {
        const categories = this.getCategoriesOptions()
        this.setState({categories});
        // this.handleFilterChange();
    }

    handleDatePreviousMonthAuxiliarButtonClick() {
        const startDate = this.getFirstDateOfPreviousMonth();
        const endDate = this.getLastDateOfPreviousMonth();
        this.setState({startDate, endDate});
        // this.handleFilterChange();
    }

    handleDateCurrentMonthAuxiliarButtonClick() {
        const startDate = this.getFirstDateOfCurrentMonth();
        const endDate = this.getLastDateOfCurrentMonth();
        this.setState({startDate, endDate});
        // this.handleFilterChange();
    }

    handleDateNextMonthAuxiliarButtonClick() {
        const startDate = moment().add(1, 'months').startOf('month').toDate();
        const endDate = moment().add(1, 'months').endOf('month').toDate();
        this.setState({startDate, endDate});
        // this.handleFilterChange();
    }

    handleDateCurrentYearAuxiliarButtonClick() {
        const startDate = this.getFirstDateOfCurrentYear()
        const endDate = this.getLastDateOfCurrentYear()
        this.setState({startDate, endDate});
        // this.handleFilterChange();
    }

    handleFilterChange() {
        const {name, startDate, endDate, isPaid, isNotPaid, accounts, chalets, categories} = this.state;
        const {expenses, onFilter} = this.props;

        console.log("startDate", startDate);
        console.log("endDate", endDate);

        // const filteredExpenses = _.filter(_.cloneDeep(expenses), this.filter);
        const filteredExpenses = _.cloneDeep(expenses)
            .filter(expense => this.filter(expense, name, startDate, endDate, accounts, chalets, categories));

        this.setState({filteredExpenses});

        onFilter(filteredExpenses);
    }

    filter(expense, nameFilter, startDateFilter, endDateFilter, accountsFilter, chaletsFilter, categoriesFilter) {
        const {name, purchaseDate, account, cabin, categories} = expense;

        const filterByName = nameFilter ? () => {
            return _.lowerCase(name).includes(_.lowerCase(nameFilter));
        } : () => true;

        const filterByDate = (startDateFilter && endDateFilter) ? () => {
            return moment(purchaseDate).isBetween(startDateFilter, endDateFilter);
        } : () => true;

        const filterByAccounts = accountsFilter.length ? () => {
            return _.map(accountsFilter, 'label').includes(account);
        } : () => true;

        const filterByChalet = chaletsFilter.length ? () => {
            return _.map(chaletsFilter, 'value').includes(cabin);
            // chalets.forEach(chalet => {
            //     if (_.map(chaletsFilter, 'label').includes(chalet)) {
            //         return true;
            //     }
            // });
        } : () => true;

        const filterByCategories = categoriesFilter.length ? () => {
            // categories.forEach(category => {
            //     if (_.map(categoriesFilter, 'label').includes(category)) {
            //         return true;
            //     }
            // });
            return categories.some(item => _.map(categoriesFilter, 'label').includes(item));
        } : () => true;


        return filterByName(name, nameFilter)
            && filterByDate()
            && filterByAccounts()
            && filterByChalet()
            && filterByCategories();
    }

    renderExpenseField() {
        const {name} = this.state;
        return (
            <div>
                <div className={"subtitle"}>Despesa</div>
                <Form.Control
                    className="text-field"
                    ref={(input) => {this._autoFocusComponentRef = input;}}
                    type="text"
                    required
                    onChange={this.handleExpenseNameChange}
                    value={name}
                    style={{textSize: "12px"}}
                />
            </div>
        );
    }

    renderStartDateDatePicker() {
        const {startDate, endDate} = this.state;
        const maxDate = endDate != null ? endDate : null;
        return (
            <div>
                <div className={"subtitle"}>Data inicial</div>
                <DatePickerWrapper
                    className="text-field"
                    onSelect={this.handleStartDateChange}
                    selected={startDate}
                    maxDate={maxDate}
                    required
                />
            </div>
        );
    }

    renderEndDateDatePicker() {
        const {endDate, startDate} = this.state;
        const minDate = startDate != null ? startDate : null;
        return (
            <div>
                <div className={"subtitle"}>Data final</div>
                <DatePickerWrapper
                    className="text-field"
                    onSelect={this.handleEndDateChange}
                    selected={endDate}
                    required
                    minDate={minDate}
                />
            </div>
        );
    }

    renderPaidCheckBox() {
        const {isPaid} = this.state;
        return (
            <Checkbox id={"expenses-content-filter-paid-checkbox-id"}
                      checked={isPaid}
                      onChange={this.handlePaidCheckBoxChange}
                      label={"Pago"}
                      className={"subtitle"}
            />
        )
    }

    renderNotPaidCheckBox() {
        const {isNotPaid} = this.state;
        return (
            <Checkbox id={"expenses-content-filter-not-paid-checkbox-id"}
                      checked={isNotPaid}
                      label={"Não pago"}
                      className={"subtitle"}
                      onChange={this.handleNotPaidCheckBoxChange}
            />
        )
    }

    renderSituationCheckbox() {
        return (
            <Form.Group>
                <Form.Label className={"subtitle"}>Estado</Form.Label>
                {this.renderPaidCheckBox()}
                {this.renderNotPaidCheckBox()}
            </Form.Group>
        )
    }

    renderCategoriesSelect() {
        const {categories} = this.state;
        return (
            <div>
                <div className={"subtitle"}>Categorias</div>
                <SelectWrapper
                    className="text-field"
                    isSearchable
                    placeholder="Selecione"
                    required
                    isMulti
                    options={this.getCategoriesOptions()}
                    value={categories}
                    onChange={this.handleCategoriesSelectChange}
                />
            </div>
        )
    }

    renderChaletsSelect() {
        const {chalets} = this.state;
        return (
            <div>
                <div className={"subtitle"}>Cabanas</div>
                <SelectWrapper
                    isSearchable
                    placeholder="Selecione"
                    required
                    isMulti
                    options={this.getChaletOptions()}
                    value={chalets}
                    onChange={this.handleChaletsSelectChange}
                />
            </div>
        )
    }

    renderAccountsSelect() {
        const {accounts} = this.state;
        return (
            <div>
                <div className={"subtitle"}>Contas</div>
                <SelectWrapper
                    isSearchable
                    placeholder="Selecione"
                    required
                    isMulti
                    options={this.getAccountsOptions()}
                    value={accounts}
                    onChange={this.handleAccountsSelectChange}
                />
            </div>
        );
    }

    renderAccountsSelectAuxiliarButton() {
        return (
            <Button style={{borderRadius: "20px", fontSize: "12px"}}
                    size="sm"
                    onClick={this.handleAccountsSelectAuxiliarButtonClick}
                    variant={"outline-primary"}
            >
                Adicionar todas
            </Button>
        )
    }

    renderChaletsSelectAuxiliarButton() {
        return (
            <Button style={{borderRadius: "20px", fontSize: "12px"}}
                    size="sm"
                    onClick={this.handleChaletsSelectAuxiliarButtonClick}
                    variant={"outline-primary"}
            >
                Adicionar todos
            </Button>
        )
    }

    renderCategoriesSelectAuxiliarButton() {
        return (
            <Button style={{borderRadius: "20px", fontSize: "12px"}}
                    size="sm"
                    onClick={this.handleCategoriesSelectAuxiliarButtonClick}
                    variant={"outline-primary"}
            >
                Adicionar todas
            </Button>
        )
    }

    renderDatePreviousMonthAuxiliarButton() {
        return (
            <Button className="float-left small-btn" style={{borderRadius: "20px", fontSize: "12px", marginRight: "4px"}}
                    size="sm"
                    onClick={this.handleDatePreviousMonthAuxiliarButtonClick}
                    variant={"outline-primary"}
            >
                Mês anterior
            </Button>
        )
    }

    renderDateCurrentMonthAuxiliarButton() {
        return (
            <Button className="float-left small-btn" style={{borderRadius: "20px", fontSize: "12px", marginRight: "4px"}}
                    size="sm"
                    onClick={this.handleDateCurrentMonthAuxiliarButtonClick}
                    variant={"outline-primary"}
            >
                Mês atual
            </Button>
        )
    }

    renderDateCurrentYearAuxiliarButton() {
        return (
            <Button className="float-left small-btn" style={{borderRadius: "20px", fontSize: "12px", marginRight: "4px"}}
                    size="sm"
                    onClick={this.handleDateCurrentYearAuxiliarButtonClick}
                    variant={"outline-primary"}
            >
                Ano atual
            </Button>
        )
    }

    renderToggleIcon() {
        return (
            <div className="OrdersOrder-toggle-icon">
                <FontAwesomeIcon icon="angle-down" size="sm"/>
            </div>
        );
    }

    render() {
        const {expenses} = this.props;
        const {filteredExpenses} = this.state;

        return (
            <Accordion className="ExpensesContentFilter" defaultActiveKey="0">
                <Accordion.Toggle as={Card.Header} className="ExpensesContentFilter-suppress-card-header ExpensesContentFilter-toggle"
                                  eventKey="0">
                    Filtrar
                    <div className={"float-right subtitle"} style={{"marginRight": "32px"}}>
                        Exibindo {filteredExpenses.length} de {expenses.length} despesas
                    </div>

                    {/*<Row>*/}
                    {/*    <Col>Filtro</Col>*/}
                    {/*</Row>*/}

                    {this.renderToggleIcon()}

                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" style={{paddingBottom: "12px"}}>
                    <Card.Body className="card-body">
                        <Row>
                            <Col md={3} className="small-col">
                                {this.renderExpenseField()}
                            </Col>
                            <Col md={3} className="small-col">
                                <Row>
                                    <Col>
                                        {this.renderStartDateDatePicker()}
                                    </Col>
                                    <Col>
                                        {this.renderEndDateDatePicker()}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col className="small-col">
                                        {this.renderAccountsSelect()}
                                        {/*{this.renderAccountsSelectAuxiliarButton()}*/}
                                    </Col>
                                    <Col className="small-col">
                                        {this.renderChaletsSelect()}
                                        {/*{this.renderChaletsSelectAuxiliarButton()}*/}
                                    </Col>
                                    <Col className="small-col">
                                        {this.renderCategoriesSelect()}
                                        {/*{this.renderCategoriesSelectAuxiliarButton()}*/}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div style={{marginBottom: "8px", marginTop: "6px"}}>
                            {this.renderDatePreviousMonthAuxiliarButton()}
                            {this.renderDateCurrentMonthAuxiliarButton()}
                            {this.renderDateCurrentYearAuxiliarButton()}
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Accordion>
        )
    }
}

export default ExpensesContentFilter;