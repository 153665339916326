import _ from "lodash";
import autoBind from "auto-bind";
import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {Button, Form, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SelectWrapper from "../../../../utils/select-wrapper/select-wrapper";

class DeliveryOrderCreateItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: null,
            orderOptions: [],
        };

        this._orderSelectRef = React.createRef();
        autoBind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const orderOptions = this.getDeliveryOrderOptions();
        this.setState({orderOptions: orderOptions});
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {order, orderOptions} = this.state;
        const {orders, deliveryOrders, isLoading} = this.props;
        return !_.isEqual(order, nextState.order)
            || !_.isEqual(orderOptions, nextState.orderOptions)
            || !_.isEqual(orders, nextProps.orders)
            || !_.isEqual(deliveryOrders, nextProps.deliveryOrders)
            || !_.isEqual(isLoading, nextProps.isLoading);
    }

    clearState() {
        this.setState({
            order: null,
            orderOptions: [],
        });
    }

    clearOrderSelectValue() {
        if (this._orderSelectRef.current != null) {
            this._orderSelectRef.current.select.clearValue();
        }
    }

    getDeliveryOrderOptions() {
        const {orders, deliveryOrders} = this.props;
        const idOrdersAlreadyPlaced = deliveryOrders.map(deliveryOrder => deliveryOrder.idOrder);

        return _.chain(orders)
            .reject(order => idOrdersAlreadyPlaced.includes(order.idOrder))
            .map(order => this.formatOrderOption(order))
            .orderBy(["label"], ["asc"])
            .value();
    }

    formatOrderOption(order) {
        if (order == null) {
            return null;
        }

        return {
            value: order.idOrder,
            label: order.client.name,
            subtitle: this.formatOrderOptionSubtitle(order)
        };
    }

    formatOrderOptionSubtitle(order) {
        const address = _.get(order, "client.deliveryAddresses[0]");

        if (address == null) {
            return null;
        }

        return address.street + ", " + address.neighborhood + " - " + address.city + ", " + address.state;
    }

    handleOrderChange(event) {
        const {orders} = this.props;

        if (event == null) {
            return;
        }

        const idOrder = event.value;
        const order = _.find(orders, _.matchesProperty("idOrder", idOrder));

        this.setState({order});
    }

    handleSubmit(event) {
        const {onInsert} = this.props;
        const {order} = this.state;
        event.preventDefault();
        event.stopPropagation();

        onInsert([order]);

        this.clearOrderSelectValue();
        this.clearState();
    }

    handleInsertAll() {
        const {orders, onInsert} = this.props;
        const {orderOptions} = this.state;

        const idsOrderOptions = _.map(orderOptions, "value");
        const availableOrders = _.filter(orders, (order) => idsOrderOptions.includes(order.idOrder));

        onInsert(availableOrders);

        this.clearOrderSelectValue();
        this.clearState();
    }

    renderOrderSelect() {
        const {order, orderOptions} = this.state;

        return (
            <SelectWrapper
                forwardRef={this._orderSelectRef}
                isSearchable
                options={orderOptions}
                placeholder="Selecione um pedido"
                required
                value={this.formatOrderOption(order)}
                onChange={this.handleOrderChange}
            />
        );
    }

    renderInsertButton() {
        const {order} = this.state;
        const disabled = order == null;

        return (
            <div>
                <Button type="submit" disabled={disabled}>
                    <FontAwesomeIcon size="sm" icon="plus"/>
                </Button>
            </div>
        );
    }

    renderInsertAllButton() {
        const {orderOptions} = this.state;
        const disabled = _.isEmpty(orderOptions);

        return (
            <div>
                <Button disabled={disabled} onClick={this.handleInsertAll}>
                    Adicionar todos
                </Button>
            </div>
        )
    }

    renderLoadingComponent() {
        const {isLoading} = this.props;
        if (isLoading) {
            return (
                <div style={{color: "grey", textAlign: "center", marginLeft: "12px"}}>
                    <div><Spinner animation="border"/></div>
                </div>
            );
        }
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                <div style={{display: "flex"}}>
                    <div style={{flex: 1}} className="mr-2">
                        {this.renderOrderSelect()}
                    </div>
                    <div className="mr-2">
                        {this.renderInsertButton()}
                    </div>
                    <div>
                        {this.renderInsertAllButton()}
                    </div>
                    <div>
                        {this.renderLoadingComponent()}
                    </div>
                </div>
            </Form>
        );
    }
}

export default withRouter(DeliveryOrderCreateItem);