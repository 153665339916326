import React, {Component} from "react";
import autoBind from "auto-bind";
import {Button, Col, Form, Modal, OverlayTrigger, Row, Spinner, Tooltip} from "react-bootstrap";
import _ from "lodash";
import QRCode from "../../../utils/qrcode/qrcode";
import {QRCodeSVG} from "qrcode.react";
import {Keys} from "../../../api/pix";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import alertify from "alertifyjs";
import SelectWrapper from "../../../utils/select-wrapper/select-wrapper";
import {Accounts} from "../../../api/expenses";


class InstallmentPaymentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSaving: false,
            account: null,
            accountInstallments: []
        };
        autoBind(this);
    }

    componentWillMount() {
        const account = this.getCurrentAccount();
        const {installments} = this.props;
        const accountInstallments = installments.filter(installment => installment.account === account.label);
        this.setState({account, accountInstallments});
    }

    displayValue(value) {
        return `R$ ${parseFloat(String(value)).toFixed(2).replace(".", ",")}`;
    }

    generateQRCode() {
        const {account, accountInstallments} = this.state;
        const receiverName = account.label;
        const key = Keys[receiverName];
        const today = new Date();
        const description = `Pagamento do mês ${today.getMonth()}/${today.getFullYear()} - ${accountInstallments.length} despesas - Cabanas Doce Recanto`.slice(0, 50);
        const amount = this.getInstallmentsTotalValue();

        return QRCode.generate(
            receiverName,
            key,
            description,
            amount
        );
    }

    getAccountsOptions() {
        const options = Object.entries(Accounts)
            .map(([key, value]) => {
            return {value: key, label: value}
        });

        return _.orderBy(options, ["label"], ["asc"]);
    }

    handlePay() {
        const {accountInstallments} = this.state;
        const {onPay, onClose} = this.props;

        this.setState({isSaving: true});
        onPay(accountInstallments)
            .then(() => {
                this.setState({isSaving: false});
                alertify.notify("Pagamento realizado!");
                onClose();
            })
            .catch(() => {
                alertify.error("Erro ao realizar pagamento!");
            });
    }

    handleCopyCodeToClipboard() {
        const {account, accountInstallments} = this.state;

        if (account == null || accountInstallments.length === 0) {
            return;
        }
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            alertify.notify("Código copiado!");
            return navigator.clipboard.writeText(this.generateQRCode());
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = this.generateQRCode();
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            alertify.notify("Código copiado!");
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        }
    }

    handleAccountSelectChange(event) {
        const {installments} = this.props;

        if (event == null) {
            return null;
        }

        const account = event;
        const accountInstallments = installments.filter(installment => installment.account === account.label);

        this.setState({account, accountInstallments});
    }

    getInstallmentsTotalValue() {
        const {accountInstallments} = this.state;
        return accountInstallments.reduce((totalValue, installment) => totalValue + installment.value, 0);
    }

    getInstallmentsTotalValueByAccount(account) {
        const {installments} = this.props;
        return installments.filter(installment => installment.account === account)
            .reduce((totalValue, installment) => totalValue + installment.value, 0);
    }

    getCurrentAccount() {
        const {account} = this.state;
        const validAccountOption = this.filterValidAccountOptions().length > 0 ? this.filterValidAccountOptions()[0] : null;
        return account ? account : validAccountOption;
    }

    filterValidAccountOptions() {
        return this.getAccountsOptions().filter(account => this.getInstallmentsTotalValueByAccount(account.label) > 0);
    }

    renderQRCode() {
        const {account, accountInstallments} = this.state;

        if (account == null || accountInstallments.length === 0) {
            return;
        }

        return (
            <div className="text-center" style={{margin: "24px"}}>
                {<QRCodeSVG value={this.generateQRCode()} size={256}/>}
            </div>
        )
    }

    renderAccountSelect() {
        return (
            <Form.Group>
                <SelectWrapper
                    isSearchable
                    placeholder="Selecione a conta"
                    required
                    options={this.filterValidAccountOptions()}
                    value={this.getCurrentAccount()}
                    onChange={this.handleAccountSelectChange}
                />
            </Form.Group>
        );
    }

    renderAccountDetails() {
        const account = this.getCurrentAccount();
        if (account != null) {
            return (
                <div>
                    <div className="title" style={{textAlign: "center", fontSize: "32px"}}>
                        <b>{this.displayValue(this.getInstallmentsTotalValue())}</b>
                    </div>
                </div>
            );
        }
    }

    renderInstallmentsDetails() {
        const {accountInstallments} = this.state;
        return (
            <div style={{marginTop: "12px"}}>
                <Row>
                    <Col md={2} style={{fontSize: "14px", textAlign: "center"}}>
                        Parcela
                    </Col>
                    <Col md={7} style={{fontSize: "14px", textAlign: "center"}}>
                        Despesa
                    </Col>
                    <Col md={3} style={{fontSize: "14px", textAlign: "center"}}>
                        Valor
                    </Col>
                </Row>
                {_.orderBy(accountInstallments, ['value'], ['desc'])
                    .map(installment => {
                    const tooltip = (
                        <Tooltip id="tooltip">
                            <div style={{fontSize: "12px"}}>
                                {installment.expenseName}
                            </div>
                        </Tooltip>
                    );

                    return (
                        <Row style={{marginTop: "4px"}}>
                            <Col md={2}>
                                <div className={"subtitle"} style={{textAlign: "center"}}>
                                    {installment.number} / {installment.numberOfInstallments}
                                </div>
                            </Col>
                            <Col md={7}>
                                <OverlayTrigger overlay={tooltip} placement={"left"}>
                                    <div className={"subtitle"} style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                                        {installment.expenseName}
                                    </div>
                                </OverlayTrigger>

                            </Col>
                            <Col md={3}>
                                <div className={"subtitle"} style={{textAlign: "center"}}>
                                    {this.displayValue(installment.value)}
                                </div>
                            </Col>
                        </Row>
                    );
                })}
            </div>
        );
    }

    render() {
        const {isSaving} = this.state;
        const {onClose} = this.props;

        return (
            <Modal show size="md" backdrop="static">
                <Modal.Header>
                    <Modal.Title>
                        Pagamento
                    </Modal.Title>
                    <Button style={{borderRadius: "20px"}}
                            size="sm"
                            variant="info"
                            className="float-right"
                            onClick={this.handleCopyCodeToClipboard}
                    >
                        Copiar código
                        <FontAwesomeIcon style={{marginLeft: "12px"}} size="lg" icon="copy"/>
                    </Button>
                </Modal.Header>


                <Modal.Body>
                    {this.renderAccountSelect()}
                    {this.renderQRCode()}
                    {this.renderAccountDetails()}
                    {this.renderInstallmentsDetails()}
                </Modal.Body>

                <Modal.Footer>
                    <Button className="mr-auto"
                            onClick={onClose}
                            variant="light"
                    >
                        Voltar
                    </Button>
                    <button className="btn btn-success" type="submit" form="products-modal-add-product-form" disabled={isSaving} onClick={this.handlePay}>
                        {isSaving ?
                            <div>
                                <Spinner animation="border" role="status" size="sm" className="mr-2"/>
                                Pagando
                            </div> : <div>Pagar</div>
                        }
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default InstallmentPaymentModal;