import {urlBase, params, resolve} from "../resources";

const url = urlBase + "/api/login";

export function login(email, password) {
    const authorization = {
        email,
        password
    };
    return fetch(url, params.get({authorization}))
        .then(response => response.json())
        .then(resolve);
}