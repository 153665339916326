import _ from "lodash";
import moment from "moment";
import "./delivery-create.scss";
import autoBind from "auto-bind";
import alertify from "alertifyjs";
import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import OrderRemote from "../../../resources/remote/order-remote";
import DatePickerWrapper from "../../../utils/datepicker/datepicker";
import DeliveryRemote from "../../../resources/remote/delivery-remote";
import DeliveryOrders from "../components/delivery-orders/delivery-orders";
import DeliveryOrderCreateItem from "../components/delivery-order-create-item/delivery-order-create-item";
import PixRemote from "../../../resources/remote/pix-remote";
import SelectWrapper from "../../../utils/select-wrapper/select-wrapper";

class DeliveryCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSaving: false,
            isLoading: false,

            orders: [],
            pixKeys: [],

            idDelivery: null,
            idNumber: null,
            deliveryDate: null,
            expectedDeliveryDate: null,
            pixKey: null,
            deliveryOrders: [],
            note: null,
        };

        this._isEditMode = false;
        autoBind(this);
    }

    async componentWillMount() {
        const {match} = this.props;
        const idDelivery = match.params.idDelivery;

        if (!_.isNil(idDelivery)) {
            this.setState({isLoading: true});
            const delivery = await DeliveryRemote.get(idDelivery);
            this.setState({isLoading: false});

            if (delivery.deliveryDate != null) {
                delivery.deliveryDate = new Date(delivery.deliveryDate);
            }
            delivery.expectedDeliveryDate = new Date(delivery.expectedDeliveryDate);
            delivery.deliveryOrders = delivery.orders;
            this._isEditMode = true;
            this.setState({...delivery});
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(nextProps, this.props)
            || !_.isEqual(nextState, this.state);
    }

    componentDidMount() {
        this.fetchOrders();
        this.fetchPixKeys();
    }

    fetchOrders() {
        this.setState({isLoading: true});
        OrderRemote.list()
            .then(orders => {
                this.setState({orders});
            })
            .catch(() => alertify.error("Erro ao buscar pedidos!"))
            .finally(() => this.setState({isLoading: false}));
    }

    fetchPixKeys() {
        PixRemote.list()
            .then(pixKeys => this.setState({pixKeys}))
            .catch(() => alertify.error("Erro ao buscar chaves Pix!"));
    }

    closePage() {
        const {history} = this.props;
        history.push("/deliveries");
    }

    handleExpectedDeliveryDateChange(event) {
        const expectedDeliveryDate = event;
        this.setState({expectedDeliveryDate});
    }

    handleDeliveryOrderInsert(orders) {
        const deliveryOrders = _.cloneDeep(this.state.deliveryOrders);
        deliveryOrders.push(...orders);
        this.setState({deliveryOrders});
    }

    handleDeliveryOrderRemove(index) {
        const deliveryOrders = _.cloneDeep(this.state.deliveryOrders);
        deliveryOrders.splice(index, 1);
        this.setState({deliveryOrders});
    }

    handleNoteChange(event) {
        const note = event.target.value;
        this.setState({note});
    }

    handlePixKeyChange(event) {
        const {pixKeys} = this.state;

        if (event == null) {
            return;
        }

        const idPixKey = event.value;
        const pixKey = _.find(pixKeys, _.matchesProperty("idPixKey", idPixKey));

        this.setState({pixKey});
    }

    handleSubmit(event) {
        const {
            actions,
            idDelivery,
            idNumber,
            deliveryOrders,
            deliveryDate,
            expectedDeliveryDate,
            pixKey,
            note
        } = this.state;

        event.preventDefault();
        event.stopPropagation();

        const delivery = {
            idDelivery,
            idNumber,
            orders: deliveryOrders,
            deliveryDate,
            expectedDeliveryDate,
            pixKey,
            note
        };

        this.setState({isSaving: true});

        const save = this._isEditMode ? DeliveryRemote.update : DeliveryRemote.create;
        save(delivery)
            .then((delivery) => {
                this.closePage();
                alertify.success(this._isEditMode ? "Entrega atualizada!" : "Entrega adicionada!");
            })
            .catch((error) => alertify.error("Não foi possível adicionar a entrega. " + error))
            .finally(() => {
                this.setState({isSaving: false});
            });
    }

    renderExpectedDateField() {
        const {expectedDeliveryDate} = this.state;
        return (
            <div className="DeliveryCreate-field-container">
                <div className="bigtitle">Data prevista</div>
                <DatePickerWrapper
                    onSelect={this.handleExpectedDeliveryDateChange}
                    selected={expectedDeliveryDate}
                    required
                />
            </div>
        );
    }

    getPixKeyOptions() {
        const {pixKeys} = this.state;
        return _.chain(pixKeys)
            .reject(_.isNull)
            .map(this.formatPixKeyOption)
            .orderBy(["label"], ["asc"])
            .value();

    }

    formatPixKeyOption(pixKey) {
        if (pixKey == null) {
            return null;
        }

        return {
            value: pixKey.idPixKey,
            label: pixKey.description,
            subtitle: pixKey.value
        }
    }

    renderPixKeySelect() {
        const {pixKey} = this.state;

        return (
            <div className="DeliveryCreate-field-container">
                <div className="bigtitle">Pix</div>
                <SelectWrapper
                    isSearchable
                    options={this.getPixKeyOptions()}
                    placeholder="Selecione uma chave"
                    required
                    value={this.formatPixKeyOption(pixKey)}
                    onChange={this.handlePixKeyChange}
                />
            </div>
        )
    }

    renderInsertDeliveryOrder() {
        const {orders, deliveryOrders, isLoading} = this.state;

        return (
            <div className="DeliveryCreate-field-container">
                <div className="bigtitle">Adicionar pedido</div>
                <DeliveryOrderCreateItem orders={orders} deliveryOrders={deliveryOrders} isLoading={isLoading}
                                         onInsert={this.handleDeliveryOrderInsert}/>
            </div>
        );
    }

    renderDeliveryOrders() {
        const {deliveryOrders} = this.state;
        return (
            <div className="DeliveryCreate-field-container">
                <div className="bigtitle">Pedidos</div>
                <DeliveryOrders orders={deliveryOrders} onRemove={this.handleDeliveryOrderRemove}/>
            </div>
        );
    }

    renderNoteField() {
        const {note} = this.state;
        return (
            <div className="DeliveryCreate-field-container">
                <div className="bigtitle">Anotações</div>
                <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={this.handleNoteChange}
                    value={note || ""}
                />
            </div>
        );
    }

    renderCloseButton() {
        const {isSaving} = this.state;
        return (
            <Button className="float-left" variant="outline-secondary" disabled={isSaving}
                    onClick={this.closePage}>
                Voltar
            </Button>
        );
    }

    renderSaveButton() {
        const {isSaving} = this.state;
        return (
            <Button className="float-right" variant="success" disabled={isSaving} type="submit">
                {isSaving &&
                <div><Spinner animation="border" role="status" size="sm" className="mr-2"/>Salvando</div>}
                {!isSaving && <div>Salvar</div>}
            </Button>
        );
    }

    renderActionButtons() {
        return (
            <div style={{position: "relative", width: "100%"}}>
                {this.renderCloseButton()}
                {this.renderSaveButton()}
            </div>
        );
    }

    render() {
        return (
            <div style={{height: "100%"}}>
                <div style={{display: "inline-block", width: "100%"}}>
                    <h3 className="float-left">{this._isEditMode ? "Atualizar" : "Nova"} entrega</h3>
                </div>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Col md={7}>
                            {this.renderInsertDeliveryOrder()}
                            <Row>
                            <Col md={6}>
                                {this.renderExpectedDateField()}
                            </Col>
                            <Col md={6}>
                                {this.renderPixKeySelect()}
                            </Col>
                        </Row>

                        {this.renderNoteField()}
                    </Col>
                    <Col md={5}>
                        {this.renderDeliveryOrders()}
                    </Col>
                </Form.Row>
                    {this.renderActionButtons()}
                </Form>
            </div>
        );
    }
}

export default withRouter(DeliveryCreate);