import React from "react";
import "./delivery-orders-item.scss";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const DeliveryOrdersItem = (props) => {
    const {order} = props;

    const handleOrderItemRemove = () => {
        const {index, onRemove} = props;
        onRemove(index);
    }

    const renderRemoveButton = () => {
        const {onRemove} = props;

        if (onRemove == null) {
            return;
        }

        return (
            <div className="float-right" style={{marginLeft: "12px"}}>
                <Button
                    variant="danger"
                    size="sm"
                    style={{padding: "2px 6px 0 6px"}}
                    onClick={handleOrderItemRemove}
                >
                    <FontAwesomeIcon icon="times"/>
                </Button>
            </div>
        );
    }

    const renderOrder = () => {
        return (
            <div>
                <div className="subtitle">Pedido</div>
                <div>{order.client.name}</div>
            </div>
        );
    }

    return (
        <div className="DeliveryOrdersItem" style={{display: "flex", flexWrap: "wrap"}}>
            <div style={{flexGrow: 1}}>
                <div className="float-left">
                    {renderOrder()}
                </div>

                <div className="float-right" style={{display: "flex", alignItems: "center"}}>
                    {renderRemoveButton()}
                </div>
            </div>
        </div>
    );
}

export default DeliveryOrdersItem;