import "./orders-order-items.scss";
import OrdersOrderItem from "../orders-order-item/orders-order-item";
import React from "react";

const OrdersOrderItems = (props) => {
    const renderDivider = (index, length) => {
        if (index !== length - 1) {
            return (<div className="OrdersOrderItems-divider"/>);
        }
    }

    const renderOrdersItens = () => {
        const {orderItems, onRemove} = props;
        return orderItems.map((orderItem, index) => {
            return (
                <div
                    id={"orders-order-items-item-" + index}
                    key={"orders-order-items-item-" + index}
                >
                    <OrdersOrderItem orderItem={orderItem} index={index} onRemove={onRemove}/>
                    {renderDivider(index, orderItems.length)}
                </div>
            );
        })
    };

    return (
        <div className="OrdersOrderItems">
            <div className="OrdersOrderItems-title">
                Itens
            </div>
            <div className="OrdersOrderItems-container">
                {renderOrdersItens()}
            </div>
        </div>
    );
}

export default OrdersOrderItems;