import './content.scss';
import {Route} from 'react-router-dom';
import Expenses from "../expenses-content/expenses-content";
import Installments from "../installments-content/installments-content";
import StocksContent from "../stock-content/stocks-content";
import ClientsContent from "../clients-content/clients-content";
import ClientsCreateClient from "../clients-content/pages/clients-create-client";
import OrdersContent from "../orders-content/orders-content";
import OrdersCreateOrder from "../orders-content/pages/orders-create-order";
import DeliveriesContent from "../deliveries-content/deliveries-content";
import DeliveryCreate from "../deliveries-content/pages/delivery-create";
import PixContent from "../pix-content/pix-content";
import {PixCreateKey} from "../pix-content/pages/pix-create-key";

const Content = (props) => {
    const {isSidenavExpanded} = props;
    return (
        <div className="ContentPage ContentPage-container" style={{marginLeft: isSidenavExpanded ? 240 : 64}}>
            <Route path='/expenses' component={Expenses}/>
            <Route path='/installments' component={Installments}/>

            <Route path='/stocks' component={StocksContent}/>

            <Route exact path='/clients' component={ClientsContent}/>
            <Route exact path='/clients/create' component={ClientsCreateClient}/>
            <Route exact path='/clients/update/:idClient' component={ClientsCreateClient}/>

            <Route exact path='/orders' component={OrdersContent}/>
            <Route exact path='/orders/create' component={OrdersCreateOrder}/>
            <Route exact path='/orders/update/:idOrder' component={OrdersCreateOrder}/>

            <Route exact path='/deliveries' component={DeliveriesContent}/>
            <Route exact path='/deliveries/create' component={DeliveryCreate}/>
            <Route exact path='/deliveries/update/:idDelivery' component={DeliveryCreate}/>

            <Route exact path='/pix' component={PixContent}/>
            <Route exact path='/pix/create' component={PixCreateKey}/>
            <Route exact path='/pix/update/:idPixKey' component={PixCreateKey}/>

            <Route exact path='/settings' component={Settings}/>
        </div>
    );
}

const Settings = () => {
    return (
        <h1>Configurações</h1>
    )
}

export default Content;