import "./orders-order-status.scss";
import {Col, Row} from "react-bootstrap";

const OrdersOrderStatus = (props) => {
    const {totalValue} = props;

    const displayValue = (value) => {
        if (value == null) {
            return parseFloat("0").toFixed(2).replace(".", ",");
        }
        return value.toFixed(2).replace(".", ",");
    }

    return (
        <div className="OrdersOrderStatus">
            <Row>
                <Col md={6}>
                    <div className="OrdersOrderStatus-title">
                        Total
                    </div>
                </Col>
                <Col md={6}>
                    <div className="OrdersOrderStatus-value" style={{textAlign: "right"}}>
                        R$ {displayValue(totalValue)}
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default OrdersOrderStatus;