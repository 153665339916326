import IconButton from "../../../utils/icon-button/icon-button";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "./pix-table.scss";

const PixTable = (props) => {
    const {pixKeys, onEdit, onRemove} = props;

    const columns = [
        {
            dataField: "description",
            text: "Descrição",
            align: "left",
            headerAlign: "center",
            sort: true,
            sortFunc: (a, b, order) => {
                if (order === 'asc') {
                    return (a > b) - (a < b)
                }
                return (a < b) - (a > b) // desc
            }
        },
        {
            dataField: "value",
            text: "Valor",
            align: "left",
            headerAlign: "center",
            sort: true,
            sortFunc: (a, b, order) => {
                if (order === 'asc') {
                    return (a > b) - (a < b)
                }
                return (a < b) - (a > b) // desc
            }
        },
        {
            dataField: 'actions',
            isDummyField: true,
            text: 'Ações',
            align: 'center',
            headerAlign: 'center',
            formatter: (cellContent, row) => {
                return (
                    <div>
                        <IconButton icon="edit" variant="outline-primary" onClick={() => onEdit(row)}/>
                        <IconButton icon="trash" variant="outline-danger" onClick={() => onRemove(row)}/>
                    </div>
                );
            }
        }
    ];

    const defaultSorted = [{
        dataField: "name",
        order: "asc"
    }];

    return (
        <div>
            <BootstrapTable
                striped
                bootstrap4
                keyField={"id"}
                data={pixKeys}
                columns={columns}
                bordered={false}
                pagination={paginationFactory()}
                defaultSorted={defaultSorted}
                size="sm"
            />
        </div>
    )
}

export default PixTable;