import {Component} from "react";
import autoBind from "auto-bind";
import * as StockRemote from "../../resources/remote/stock-remote";
import {Spinner} from "react-bootstrap";
import {Route, withRouter} from "react-router-dom";
import StocksTable from "./stocks-table";
import StocksOperationModal from "./stocks-operation-modal";
import _ from "lodash";
import alertify from "alertifyjs";

class StocksContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stocks: [],
            isLoading: false,
        };

        autoBind(this);
    }

    componentDidMount() {
        this.setState({isLoading: true});
        StockRemote.list()
            .then((stocks) => {
                this.setState({isLoading: false, stocks});
            });
    }

    openAddStockModal(idStock) {
        const {history} = this.props;
        history.push(`/stocks/add/${idStock}`);
    }

    openSubStockModal(idStock) {
        const {history} = this.props;
        history.push(`/stocks/sub/${idStock}`);
    }

    closeStockOperationModal() {
        const {history} = this.props;
        history.push('/stocks');
    }

    handleOpenAddStockModal(row) {
        const {idStock} = row;
        this.openAddStockModal(idStock);
    }

    handleOpenSubStockModal(row) {
        const {idStock} = row;
        this.openSubStockModal(idStock);
    }

    handleOperationStock(idStock, amount, isAdd) {
        if (idStock == null || amount <= 0) {
            return;
        }

        const {stocks} = this.state;

        const stock = _.find(stocks, _.matchesProperty("idStock", idStock));
        isAdd ? stock.amount += amount : stock.amount -= amount;
        stock.date = Math.floor(Date.now() / 1000);

        return StockRemote.update(stock)
            .then((stock) => {
                const index = stocks.indexOf(_.find(stocks, _.matchesProperty("idStock", idStock)));
                stocks.splice(index, 1, stock);
                this.setState({stocks});
                alertify.success("Estoque atualizado!");
            })
            .catch((error) => {
                const message = "Não foi possível atualizar o estoque. " + error;
                alertify.error(message);
            });
    }

    renderAddStockModal() {
        const {stocks} = this.state;

        return (
            <Route
                path="/stocks/add/:idStock"
                render={(props) => (
                    <StocksOperationModal {...props}
                                          isAddMode
                                          stocks={stocks}
                                          onSave={(idStock, amount) => this.handleOperationStock(idStock, amount, true)}
                                          onClose={this.closeStockOperationModal}
                    />
                )}
            />
        );
    };

    renderSubStockModal() {
        const {stocks} = this.state;

        return (
            <Route
                path="/stocks/sub/:idStock"
                render={(props) => (
                    <StocksOperationModal {...props}
                                          isSubMode
                                          stocks={stocks}
                                          onSave={(idStock, amount) => this.handleOperationStock(idStock, amount, false)}
                                          onClose={this.closeStockOperationModal}
                    />
                )}
            />
        );
    }

    renderStocksTable() {
        const {isLoading, stocks} = this.state;

        if (isLoading) {
            return this.renderLoadingComponent();
        }

        return (
            <StocksTable
                stocks={stocks}
                onAdd={this.handleOpenAddStockModal}
                onSub={this.handleOpenSubStockModal}
            />
        );
    }

    renderLoadingComponent() {
        return (
            <div style={{color: "grey", textAlign: "center"}}>
                <div><Spinner animation="border"/></div>
                <div>Carregando estoque</div>
            </div>
        )
    }

    render() {
        return (
            <div>
                <div style={{display: "inline-block", width: "100%"}}>
                    <h3 className="float-left">Estoque</h3>
                </div>
                {this.renderStocksTable()}
                {this.renderAddStockModal()}
                {this.renderSubStockModal()}
            </div>
        )
    }
}

export default withRouter(StocksContent);