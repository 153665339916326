import moment from "moment/moment";
import { Button, Col, Row} from "react-bootstrap";
import React from "react";
import "./installment-item.scss";


const InstallmentItem = (props) => {
    const {installment, onPay} = props;

    const displayDate = (date) => {
        return moment(date).format('DD/MM/YYYY');
    }

    const displayValue = (value) => {
        return `R$ ${parseFloat(String(value)).toFixed(2).replace(".", ",")}`;
    }

    return (
        <div className="InstallmentItem">
            <Row>
                <Col md={1}>
                    <div>
                        <div className="subtitle">ID Parcela</div>
                        <div className="title">{installment.idInstallment}</div>
                    </div>
                </Col>
                <Col md={1}>
                    <div>
                        <div className="subtitle">ID Despesa</div>
                        <div className="title">{installment.idExpense}</div>
                    </div>
                </Col>
                <Col>
                    <div>
                        <div className="subtitle">Despesa</div>
                        <div className="title">{installment.expenseName}</div>
                    </div>

                </Col>
                <Col md={1}>
                    <div className="subtitle">Parcela</div>
                    <div className="title">{installment.number} / {installment.numberOfInstallments}</div>
                </Col>
                <Col md={1}>
                    <div>
                        <div className="subtitle">Data de vencimento</div>
                        <div className="title">{displayDate(installment.dueDate)}</div>
                    </div>
                </Col>
                <Col md={1}>
                    <div>
                        <div className="subtitle">Valor</div>
                        <div className="title">{displayValue(installment.value)}</div>
                    </div>
                </Col>
                <Col md={1}>
                    <div>
                        <div className="subtitle">Situação</div>
                        <div className="title">{installment.situation === "PAID" ? "Pago" : "Em aberto"}</div>
                    </div>
                </Col>
                <Col md={1}>
                    <div>
                        <div className="subtitle">Conta</div>
                        <div className="title">{installment.account}</div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default InstallmentItem;