import "./delivery-orders.scss";
import autoBind from "auto-bind";
import alertify from "alertifyjs";
import React, {Component} from "react";
import {Spinner} from "react-bootstrap";
import DeliveryRemote from "../../../../resources/remote/delivery-remote";
import DeliveryOrdersItem from "../delivery-orders-item/delivery-orders-item";
import _ from "lodash";

class DeliveryOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: null,
        };

        this._isFetchingDeliveryOrders = false;

        autoBind(this);
    }


    renderLoadingComponent() {
        return (
            <div style={{color: "grey", textAlign: "center"}}>
                <div><Spinner animation="border"/></div>
            </div>
        );
    }

    renderDivider(index, length) {
        if (index !== length - 1) {
            return (
                <div className="DeliveryOrders-divider"/>
            );
        }
    }

    renderDeliveryOrders() {
        const {onRemove, orders} = this.props;

        if (orders == null) {
            return;
        }

        return orders.map((order, index) => {
            return (
                <div
                    id={"deliveries-delivery-orders-" + index}
                    key={"deliveries-delivery-orders-" + index}
                >
                    <DeliveryOrdersItem order={order} index={index} onRemove={onRemove}/>
                    {this.renderDivider(index, orders.length)}
                </div>
            );
        });
    }

    render() {
        const {isLoading} = this.props;

        if (isLoading) {
            return this.renderLoadingComponent();
        }

        return (
            <div className="DeliveryOrders">
                <div className="DeliveryOrders-container">
                    {this.renderDeliveryOrders()}
                </div>
            </div>
        );
    }
}

export default DeliveryOrders;