import IconButton from "../../../../utils/icon-button/icon-button";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import './clients-delivery-address-table.scss';


const ClientsDeliveryAddressTable = (props) => {
    const {deliveryAddresses, onEdit, onRemove} = props;

    const columns = [
        {
            dataField: 'description',
            text: 'Descrição',
            align: 'center',
            headerAlign: 'center',
            sort: true,
            sortFunc: (a, b, order) => {
                if (order === 'asc') {
                    return (a > b) - (a < b)
                }
                return (a < b) - (a > b) // desc
            }
        },
        {
            dataField: 'street',
            text: 'Endereço',
            align: 'center',
            headerAlign: 'center',
            sort: true,
            sortFunc: (a, b, order) => {
                if (order === 'asc') {
                    return (a > b) - (a < b)
                }
                return (a < b) - (a > b) // desc
            }
        },
        {
            dataField: 'number',
            text: 'Número',
            align: 'center',
            headerAlign: 'center',
            sort: true,
            sortFunc: (a, b, order) => {
                if (order === 'asc') {
                    return (a > b) - (a < b)
                }
                return (a < b) - (a > b) // desc
            }
        },
        {
            dataField: 'complement',
            text: 'Complemento',
            align: 'center',
            headerAlign: 'center',
            sort: true,
            sortFunc: (a, b, order) => {
                if (order === 'asc') {
                    return (a > b) - (a < b)
                }
                return (a < b) - (a > b) // desc
            }
        },
        {
            dataField: 'actions',
            isDummyField: true,
            text: 'Ações',
            align: 'center',
            headerAlign: 'center',
            formatter: (cellContent, row) => {
                return (
                    <div>
                        <IconButton icon="edit" variant="outline-primary" onClick={() => onEdit(row)}/>
                        <IconButton icon="trash" variant="outline-danger" onClick={() => onRemove(row)}/>
                    </div>
                );
            }
        }
    ];

    const defaultSorted = [{
        dataField: "name",
        order: "asc"
    }];

    return (
        <div className="ProductsTable">
            <BootstrapTable
                striped
                bootstrap4
                keyField={'id'}
                data={deliveryAddresses}
                columns={columns}
                bordered={false}
                pagination={paginationFactory()}
                defaultSorted={defaultSorted}
                size="sm"
            />
        </div>
    );
}

export default ClientsDeliveryAddressTable;