import {Form} from "react-bootstrap";
import DatePicker, {registerLocale} from  "react-datepicker";
import pt from 'date-fns/locale/pt';
registerLocale('pt', pt)

const DatePickerWrapper = (props) => {
    return (
        <Form.Control
            as={DatePicker}
            dateFormat="dd/MM/yyyy"
            showYearDropdown
            locale="pt"
            {...props}
        />
    );
}

export default DatePickerWrapper;