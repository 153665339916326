import _ from "lodash";
import autoBind from "auto-bind";
import alertify from "alertifyjs";
import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {Button, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DeliveryRemote from "../../resources/remote/delivery-remote";
import DeliveryItem from "./components/delivery-item/delivery-item";

class DeliveriesContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveries: [],
            isLoading: false,
            isSavingCompleteDeliveries: false,
        };

        autoBind(this);
    }

    componentDidMount() {
        this.setState({isLoading: true});
        DeliveryRemote.list()
            .then((deliveries) => {
                this.setState({deliveries});
            })
            .catch((error) => alertify.error("Erro ao buscar entregas. " + error))
            .finally(() => this.setState({isLoading: false}));
    }

    handleOpenPageCreateDelivery() {
        const {history} = this.props;
        history.push("/deliveries/create");
    }

    handleDeliveryRemove(delivery) {
        const {deliveries} = this.state;
        const removeDelivery = () => {
            DeliveryRemote.remove(delivery.idDelivery)
                .then(() => {
                    _.remove(deliveries, delivery);
                    this.setState({deliveries});
                    alertify.success("Entrega apagada!");
                })
                .catch(() => alertify.error("Erro ao apagar entrega!"));
        };

        alertify.confirm(
            "Apagar entrega: #" + delivery.idNumber,
            "A exclusão da entrega #" + delivery.idNumber + " não poderá ser desfeita.",
            removeDelivery,
            _.noop
        );
    }

    handleDeliveryEdit(delivery) {
        const {history} = this.props;
        history.push(`/deliveries/update/${delivery.idDelivery}`)
    }

    handleCompleteDelivery(delivery) {
        const {deliveries} = this.state;

        Object.assign(_.find(deliveries, _.matchesProperty("idDelivery", delivery.idDelivery)), delivery);
        this.setState({deliveries});
        alertify.success("Entrega efetuada!");
    }

    renderDeliveryItems() {
        const {isLoading, deliveries, checkAll, checkNone} = this.state;

        if (isLoading) {
            return this.renderLoadingComponent();
        }

        return _.orderBy(deliveries, ["idNumber"], ["desc"])
            .map(delivery => {
                return (
                    <div id={"deliveries-content-delivery-" + delivery.idDelivery}
                         key={"deliveries-content-delivery-" + delivery.idDelivery}
                    >
                        <DeliveryItem
                            delivery={delivery}
                            checkAll={checkAll}
                            checkNone={checkNone}
                            onRemove={this.handleDeliveryRemove}
                            onEdit={this.handleDeliveryEdit}
                            onCompleteDelivery={this.handleCompleteDelivery}
                        />
                    </div>
                )
            });
    }

    renderLoadingComponent() {
        return (
            <div style={{color: "grey", textAlign: "center"}}>
                <div><Spinner animation="border"/></div>
                <div>Carregando entregas</div>
            </div>
        );
    }

    render() {
        return (
            <div>
                <div style={{display: "inline-block", width: "100%"}}>
                    <h3 className="float-left">Entregas</h3>
                    <Button variant="primary" size="md" className="float-right"
                            onClick={this.handleOpenPageCreateDelivery}>
                        <FontAwesomeIcon size="sm" icon="plus" className="mr-2"/>
                        Nova entrega
                    </Button>
                </div>
                {this.renderDeliveryItems()}
            </div>
        );
    }
}

export default withRouter(DeliveriesContent);