import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons';
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/default.min.css";
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import "react-datepicker/dist/react-datepicker.css";
import 'icheck-bootstrap/icheck-bootstrap.min.css';
import {BrowserRouter} from "react-router-dom";
import moment from "moment";
library.add(fas);

Date.prototype.toJSON = function () {
    return moment(this).format("YYYY-MM-DDTHH:mm:ss:ms") + "Z";
}

ReactDOM.render(
  // <React.StrictMode>
    <BrowserRouter>
        <App />
    </BrowserRouter>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
