import React, {Component} from "react";
import autoBind from "auto-bind";
import InstallmentRemote from "../../resources/remote/installment-remote";
import ExpenseRemote from "../../resources/remote/expense-remote";
import {Button, Spinner} from "react-bootstrap";
import InstallmentsContentFilter from "./installments-content-filter/installments-content-filter";
import {Route, withRouter} from "react-router-dom";
import _ from "lodash";
import InstallmentItem from "./installment-item/installment-item";
import InstallmentPaymentModal from "./installment-payment-modal/installment-payment-modal";
import alertify from "alertifyjs";


class InstallmentsContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expenses: [],
            isLoading: false,

            filteredInstallments: [],
        };

        autoBind(this);
    }

    componentDidMount() {
        this.setState({isLoading: true});
        // list dependent of the filter
        ExpenseRemote.list()
            .then(expenses => this.setState({isLoading: false, expenses}));
    }


    handleFilterInstallments(filteredInstallments) {
        this.setState({filteredInstallments});
    }

    handleOpenInstallmentPaymentModal(idInstallment) {
        const {history} = this.props;
        history.push("/installments/payment/" + idInstallment)
    }

    handleOpenPaymentModal() {
        const {history} = this.props;
        history.push("/installments/payment")
    }

    handleCloseInstallmentPaymentModal() {
        const {history} = this.props;
        history.push("/installments");
    }

    handlePayInstallmentsPaymentModal(installmentsToPay) {
        const {expenses} = this.state;
        const expensesCopy = _.cloneDeep(expenses);

        return InstallmentRemote.pay(installmentsToPay)
            .then(installments => {
                installments.forEach(installment => {
                    const expenseCopy = _.find(expensesCopy, _.matchesProperty("idExpense", installment.idExpense));
                    let installmentCopy = _.find(expenseCopy.installments, _.matchesProperty("idInstallment", installment.idInstallment));
                    Object.assign(installmentCopy, installment);
                });

                this.setState({expenses: expensesCopy});
                alertify.success("Pagamento efetuado!");
            })
            .catch(error => {
                let message = error;
                if (_.isEmpty(error))
                    message = "Erro ao efetuar pagamento!"
                alertify.error(message);
            });
    }

    renderFilter() {
        const {expenses} = this.state;
        return (
            <InstallmentsContentFilter expenses={expenses}
                                   onFilter={this.handleFilterInstallments}
            />
        )
    }

    renderLoadingComponent() {
        return (
            <div style={{color: "grey", textAlign: "center"}}>
                <div><Spinner animation="border"/></div>
            </div>
        );
    }

    renderPaymentButton() {
        const {filteredInstallments, isLoading} = this.state;

        if (isLoading || filteredInstallments.length === 0)
            return;

        return (
            <Button style={{textAlign: "right", borderRadius: "10px", fontSize: "16px", margin: "12px"}}
                    size="sm"
                    variant={"success"}
                    onClick={this.handleOpenPaymentModal}
            >
                Pagar
            </Button>
        );
    }

    renderInstallments() {
        const {isLoading, filteredInstallments} = this.state;

        if (isLoading) {
            return this.renderLoadingComponent();
        }

        if (_.isEmpty(filteredInstallments)) {
            return (
                <div>Não há parcelas disponíveis.</div>
            )
        }

        return _.orderBy(filteredInstallments, ['idInstallment'], ['desc']).map(installment => {
            return (
                <div
                    id={"expenses-content-expense-item-" + installment.idInstallment}
                    key={"expenses-content-expense-item-" + installment.idInstallment}
                >
                    <InstallmentItem installment={installment}
                                     onCheck={this.handleCheckPayInstallment}
                                     onPay={this.handleOpenInstallmentPaymentModal}
                    ></InstallmentItem>
                </div>
            )
        })
    }

    renderInstallmentPaymentModal() {
        const {filteredInstallments} = this.state;
        return (
            <Route path='/installments/payment'
                   render={(props) => (
                       <InstallmentPaymentModal installments={filteredInstallments}
                                                {...props}
                                                onClose={this.handleCloseInstallmentPaymentModal}
                                                onPay={this.handlePayInstallmentsPaymentModal}
                       />
                   )}
            />
        )
    }

    render() {
        return (
            <div>
                <div style={{display: "inline-block", width: "100%"}}>
                    <h3 className='float-left small-title'>Parcelas</h3>
                </div>
                {this.renderFilter()}
                {this.renderPaymentButton()}
                {this.renderInstallments()}
                {this.renderInstallmentPaymentModal()}
                {/*{this.renderExpensesModalNewExpense()}*/}
                {/*{this.renderExpensesModalEditExpense()}*/}
            </div>
        );
    }
}

export default withRouter(InstallmentsContent);