import React, {Component} from "react";
import {Button, Col, Form, InputGroup, Modal, Spinner} from "react-bootstrap";
import NumberFormat from "react-number-format";
import SelectWrapper from "../../../utils/select-wrapper/select-wrapper";
import CepRemote from "../../../resources/remote/cep-remote";
import alertify from "alertifyjs";
import _ from "lodash";
import autoBind from "auto-bind";
import "./clients-delivery-address-modal.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class ClientsDeliveryAddressModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: {
                submited: false,
                cep: false,
            },
            description: null,
            cep: null,
            street: null,
            number: null,
            complement: null,
            neighborhood: null,
            city: null,
            state: null,
            note: null,

            isFetchingCep: false,
        };

        this._autoFocusComponentRef = null;

        autoBind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {validated} = this.state;
        const {isEditMode, deliveryAddress, show} = this.props;

        if (!prevProps.show && show) {
            this._autoFocusComponentRef.focus();

            if (isEditMode && !_.isNull(deliveryAddress)) {
                Object.keys(validated).forEach(key => validated[key] = true);
                this.setState({...deliveryAddress});
            }
        }
    }

    clearState() {
        const state = {
            validated: {
                submited: false,
                cep: false,
            },
            description: null,
            cep: null,
            street: null,
            number: null,
            complement: null,
            neighborhood: null,
            city: null,
            state: null,
            note: null,

            isFetchingCep: false,
        };
        this.setState(state);
    }

    getStateOptions() {
        return [
            {value: "AC", label: "Acre"},
            {value: "AL", label: "Alagoas"},
            {value: "AP", label: "Amapá"},
            {value: "AM", label: "Amazonas"},
            {value: "BA", label: "Bahia"},
            {value: "CE", label: "Ceará"},
            {value: "DF", label: "Distrito Federal"},
            {value: "ES", label: "Espírito Santo"},
            {value: "GO", label: "Goiás"},
            {value: "MA", label: "Maranhão"},
            {value: "MT", label: "Mato Grosso"},
            {value: "MS", label: "Mato Grosso do Sul"},
            {value: "MG", label: "Minas Gerais"},
            {value: "PA", label: "Pará"},
            {value: "PB", label: "Paraíba"},
            {value: "PR", label: "Paraná"},
            {value: "PE", label: "Pernambuco"},
            {value: "PI", label: "Piauí"},
            {value: "RJ", label: "Rio de Janeiro"},
            {value: "RN", label: "Rio Grande do Norte"},
            {value: "RS", label: "Rio Grande do Sul"},
            {value: "RO", label: "Roraima"},
            {value: "RR", label: "Rondônia"},
            {value: "SC", label: "Santa Catarina"},
            {value: "SP", label: "São Paulo"},
            {value: "SE", label: "Sergipe"},
            {value: "TO", label: "Tocantins"},
        ];
    }

    getSelectedStateOption(value) {
        const selectedOption = _.find(this.getStateOptions(), _.matchesProperty("value", value));
        const label = _.get(selectedOption, "label");

        if (label == null) {
            return;
        }

        return {label};
    }

    getDefaultValue(path) {
        const {isEditMode, deliveryAddress} = this.props;
        if (isEditMode) {
            return _.get(deliveryAddress, path, undefined);
        }
        return undefined;
    }

    isFormValidated() {
        const {validated} = this.state;
        return Object.values(validated).reduce((result, value) => result && value);
    }

    isValidCep(cep) {
        return cep.match(/[0-9]{5}-[0-9]{3}/) != null;
    }

    handleSubmit(event) {
        const {description, cep, street, number, complement, neighborhood, city, state, note, validated} = this.state;
        const {isEditMode, onCreate, onEdit, onClose, deliveryAddress} = this.props;

        event.preventDefault();
        event.stopPropagation();

        validated.submited = true;
        this.setState({validated});

        if (this.isFormValidated()) {
            const newDeliveryAddress = {description, cep, street, number, complement, neighborhood, city, state, note};

            if (isEditMode) {
                onEdit(deliveryAddress, _.cloneDeep(newDeliveryAddress));
            } else {
                onCreate(_.cloneDeep(newDeliveryAddress));
            }

            onClose();
            this.clearState();
        }
    }

    handleDescriptionChange(event) {
        const description = event.target.value;
        this.setState({description});
    }

    handleCepChange(event) {
        const {validated, cep} = this.state;
        const newCep = String(event.target.value);
        const formatedCep = newCep.replace("-", "");

        if (formatedCep === cep) {
            return;
        }

        validated.cep = this.isValidCep(formatedCep);
        this.setState({cep: formatedCep, validated});

        if (this.isValidCep(newCep)) {
            this.setState({isFetchingCep: true});
            CepRemote.get(formatedCep)
                .then((data) => {
                    const {cep, state, city, neighborhood, street} = data;
                    validated.cep = true;
                    this.setState({cep, state, city, neighborhood, street, validated, isFetchingCep: false});
                })
                .catch((error) => {
                    validated.cep = false;
                    this.setState({validated, isFetchingCep: false});
                    const message = "CEP não encontrado!"
                    alertify.error(message);
                });
        }
    }

    handleStreetChange(event) {
        const street = event.target.value;
        this.setState({street});
    }

    handleNumberChange(event) {
        const number = event.target.value;
        this.setState({number});
    }

    handleComplementChange(event) {
        const complement = event.target.value;
        this.setState({complement});
    }

    handleNeighborhoodChange(event) {
        const neighborhood = event.target.value;
        this.setState({neighborhood});
    }

    handleCityChange(event) {
        const city = event.target.value;
        this.setState({city});
    }

    handleStateChange(event) {
        const state = event.value;
        this.setState({state});
    }

    handleNoteChange(event) {
        const note = event.target.value;
        this.setState({note});
    }

    renderDescriptionField() {
        return (
            <Form.Group>
                <Form.Label>Descrição do endereço</Form.Label>
                <Form.Control
                    ref={(input) => {this._autoFocusComponentRef = input;}}
                    type="text"
                    required
                    onChange={this.handleDescriptionChange}
                    defaultValue={this.getDefaultValue("description")}
                />
            </Form.Group>
        )
    }

    renderCepField() {
        const {validated} = this.state;
        return (
            <Form.Group>
                <Form.Label>CEP</Form.Label>
                <InputGroup>
                    <Form.Control
                        type="text"
                        required
                        as={NumberFormat}
                        format="#####-###"
                        mask="_"
                        onChange={this.handleCepChange}
                        defaultValue={this.getDefaultValue("cep")}
                        isInvalid={validated.submited && !validated.cep}
                    />
                    <InputGroup.Append>
                        <InputGroup.Text>
                            {this.renderCepFieldStatus()}
                        </InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>

                <Form.Control.Feedback type="invalid">Este CEP não é válido!</Form.Control.Feedback>
            </Form.Group>
        );
    }

    renderCepFieldStatus() {
        const {isFetchingCep, validated} = this.state;
        if (isFetchingCep) {
            return (<Spinner animation="border" role="status" size="sm"/>);
        }

        if (validated.cep) {
            return (<FontAwesomeIcon size="sm" icon="check"/>);
        }

        return (<FontAwesomeIcon size="sm" icon="times"/>);
    }

    renderStreetField() {
        const {street} = this.state;
        return (
            <Form.Group>
                <Form.Label>Endereço</Form.Label>
                <Form.Control
                    type="text"
                    required
                    value={street}
                    defaultValue={this.getDefaultValue("street")}
                    onChange={this.handleStreetChange}
                />
            </Form.Group>
        );
    }

    renderNumberField() {
        return (
            <Form.Group>
                <Form.Label>Número</Form.Label>
                <Form.Control
                    type="number"
                    required
                    onChange={this.handleNumberChange}
                    min={0}
                    defaultValue={this.getDefaultValue("number")}
                />
            </Form.Group>
        );
    }

    renderComplementField() {
        return (
            <Form.Group>
                <Form.Label>Complemento</Form.Label>
                <Form.Control
                    type="text"
                    required
                    onChange={this.handleComplementChange}
                    defaultValue={this.getDefaultValue("complement")}
                />
            </Form.Group>
        );
    }

    renderNeighborhoodField() {
        const {neighborhood} = this.state;
        return (
            <Form.Group>
                <Form.Label>Bairro</Form.Label>
                <Form.Control
                    type="text"
                    required
                    value={neighborhood}
                    onChange={this.handleNeighborhoodChange}
                    defaultValue={this.getDefaultValue("neighborhood")}
                />
            </Form.Group>
        );
    }

    renderCityField() {
        const {city} = this.state;
        return (
            <Form.Group>
                <Form.Label>Cidade</Form.Label>
                <Form.Control
                    type="text"
                    required
                    value={city}
                    onChange={this.handleCityChange}
                    defaultValue={this.getDefaultValue("city")}
                />
            </Form.Group>
        );
    }

    renderStateField() {
        const {state} = this.state;
        return (
            <Form.Group>
                <Form.Label>Estado</Form.Label>
                <SelectWrapper
                    isSearchable
                    options={this.getStateOptions()}
                    placeholder="Selecione uma opção"
                    value={this.getSelectedStateOption(state)}
                    defaultValue={this.getSelectedStateOption(this.getDefaultValue("state"))}
                    onChange={this.handleStateChange}
                    required
                />
            </Form.Group>
        );
    }

    renderNoteField() {
        const {note} = this.state;
        return (
            <Form.Group>
                <Form.Label>Observações</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    value={note}
                    onChange={this.handleNoteChange}
                    defaultValue={this.getDefaultValue("note")}
                />
                <Form.Text muted>Opcional</Form.Text>
            </Form.Group>
        );
    }

    renderModalHeader() {
        const {isEditMode} = this.props;
        return (
            <Modal.Header>
                <Modal.Title>
                    {isEditMode ? "Atualizar" : "Adicionar"} endereço de entrega
                </Modal.Title>
            </Modal.Header>
        );
    }

    renderModalBody() {
        return (
            <Modal.Body className="ClientsDeliveryAddressModalBody">
                <Form.Group>
                    <Form.Row>
                        <Col md={12}>{this.renderDescriptionField()}</Col>
                    </Form.Row>
                    <Form.Row>
                        <Col md={3}>{this.renderCepField()}</Col>
                        <Col md={4}>{this.renderStreetField()}</Col>
                        <Col md={2}>{this.renderNumberField()}</Col>
                        <Col md={3}>{this.renderComplementField()}</Col>
                    </Form.Row>
                    <Form.Row>
                        <Col md={4}>{this.renderNeighborhoodField()}</Col>
                        <Col md={4}>{this.renderCityField()}</Col>
                        <Col md={4}>{this.renderStateField()}</Col>
                    </Form.Row>
                    <Form.Row>
                        <Col md={12}>{this.renderNoteField()}</Col>
                    </Form.Row>
                </Form.Group>
            </Modal.Body>
        );
    }

    renderModalFooter() {
        const {isEditMode, onClose} = this.props;
        return (
            <Modal.Footer>
                <Button
                    className="mr-auto"
                    variant="light"
                    onClick={onClose}
                >
                    Cancelar
                </Button>
                <Button type="submit" variant="success">
                    {isEditMode ? "Atualizar" : "Adicionar"}
                </Button>
            </Modal.Footer>
        );
    }

    render() {
        const {show} = this.props;
        return (
            <Modal show={show} size="lg" backdrop="static">
                <Form onSubmit={this.handleSubmit}>
                    {this.renderModalHeader()}
                    {this.renderModalBody()}
                    {this.renderModalFooter()}
                </Form>
            </Modal>
        );
    }
}

export default ClientsDeliveryAddressModal;