import _ from "lodash";
import moment from "moment";
import autoBind from "auto-bind";
import alertify from "alertifyjs";
import {saveAs} from "file-saver";
import "./delivery-item-content.scss";
import React, {Component} from "react";
import {Button, Col, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DeliveryOrders from "../delivery-orders/delivery-orders";
import TagsRemote from "../../../../resources/remote/tags-remote";
import DeliveryRemote from "../../../../resources/remote/delivery-remote";
import ChecklistsRemote from "../../../../resources/remote/checklists-remote";

class DeliveryItemContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isGeneratingTags: false,
            isGeneratingChecklist: false,
            isCompletingDelivery: false,
            isLoadingOrders: false,
            // orders: null
        };

        this._isFetchingDeliveryOrders = false;

        autoBind(this);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {isAccordionOpen} = this.props;
        const {orders} = this.state;

        const isTheFirstTimeAccordionHasBeenOpened = nextProps.isAccordionOpen
            && isAccordionOpen !== nextProps.isAccordionOpen
            && orders == null;

        return !_.isEqual(nextState, this.state) ||
            !_.isEqual(nextProps, this.props) ||
            isTheFirstTimeAccordionHasBeenOpened;
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     const {delivery, isAccordionOpen} = this.props;
    //     const {orders} = this.state;
    //
    //     if (isAccordionOpen && orders == null && !this._isFetchingDeliveryOrders) {
    //         this._isFetchingDeliveryOrders = true;
    //         this.setState({isLoadingOrders: true});
    //         DeliveryRemote.getDeliveryOrders(delivery.idDelivery)
    //             .then((orders) => {
    //                 this.setState({orders});
    //             })
    //             .catch((error) => alertify.error(`Erro ao buscar pedidos: ${error}`))
    //             .finally(() => {
    //                 this.setState({isLoadingOrders: false})
    //                 this._isFetchingDeliveryOrders = false;
    //             });
    //     }
    // }

    displayDate(date) {
        return moment(date).format('DD/MM/YYYY');
    }

    async handleGenerateTags() {
        const {delivery} = this.props;
        const {isGeneratingTags} = this.state;

        if (isGeneratingTags) {
            return;
        }

        this.setState({isGeneratingTags: true});

        if (_.isEmpty(delivery.orders)) {
            await DeliveryRemote.getDeliveryOrders(delivery.idDelivery).then((orders) => {
                delivery.orders = orders;
            });
        }

        delivery.orders = this.sortDeliveryOrders(delivery.orders);

        TagsRemote.generate(delivery)
            .then(pdf => {
                saveAs(pdf, 'Etiquetas.pdf');
            })
            .finally(() => this.setState({isGeneratingTags: false}));
    }

    async handleGenerateChecklist() {
        const {delivery} = this.props;
        const {isGeneratingChecklist} = this.state;

        if (isGeneratingChecklist) {
            return;
        }

        this.setState({isGeneratingChecklist: true})

        if (_.isEmpty(delivery.orders)) {
            await DeliveryRemote.getDeliveryOrders(delivery.idDelivery).then((orders) => {
                delivery.orders = orders;
            });
        }

        ChecklistsRemote.generate(delivery)
            .then(pdf => {
                saveAs(pdf, 'Lista de conferência.pdf');
            })
            .finally(() => this.setState({isGeneratingChecklist: false}));
    }

    handleCompleteDelivery() {
        const {delivery, onCompleteDelivery} = this.props;

        this.setState({isCompletingDelivery: true});

        DeliveryRemote.completeDelivery(delivery.idDelivery)
            .then((delivery) => {
                onCompleteDelivery(delivery);
            })
            .catch((error) => {
                alertify.error(`Erro ao efetuar entrega: ${error}`);
            })
            .finally(() => {
                this.setState({isCompletingDelivery: false});
            });
    }

    sortDeliveryOrders(orders) {
        return _.orderBy(orders, ["deliveryAddress.neighborhood", "asc"], ["client.name", "asc"]);
    }

    renderCompleteDeliveryButton() {
        const {delivery} = this.props;
        const {isCompletingDelivery} = this.state;

        if (delivery.status !== 'NOT_DELIVERED') {
            return;
        }

        return (
            <Button style={{borderRadius: "20px"}}
                    size="sm"
                    variant="outline-success"
                    className="mr-2"
                    onClick={this.handleCompleteDelivery}
                    disabled={isCompletingDelivery}
            >
                {!isCompletingDelivery &&
                <div>
                    <FontAwesomeIcon size="sm" icon={'truck-loading'} className="mr-2"/>
                    Efetuar entrega
                </div>
                }

                {isCompletingDelivery &&
                <div>
                    <Spinner animation="border" role="status" size="sm" className="mr-2"/>
                    Efetuando entrega
                </div>
                }
            </Button>

        );
    }

    // renderChecklistsButton() {
    //     const {isGeneratingChecklist} = this.props;
    //
    //     return (
    //         <Dropdown as={ButtonGroup}>
    //             <Dropdown.Toggle style={{borderRadius: "20px"}}
    //                              size="sm"
    //                              variant="outline-info"
    //                              className="mr-2"
    //                              disabled={isGeneratingChecklist}>
    //                     {!isGeneratingChecklist &&
    //                     "Gerar listas"
    //                     }
    //
    //                     {isGeneratingChecklist &&
    //                     "Gerando listas de conferência"
    //                     }
    //             </Dropdown.Toggle>
    //             <Dropdown.Menu>
    //                 <Dropdown.Item onClick={this.handleGenerateChecklist}>
    //                     <div>
    //                         <FontAwesomeIcon size="sm" icon="paper-plane" className="mr-2"/>
    //                         Envio
    //                     </div>
    //                 </Dropdown.Item>
    //                 <Dropdown.Item>
    //                     <div>
    //                         <FontAwesomeIcon size="sm" icon="truck-loading" className="mr-2"/>
    //                         Entrega
    //                     </div>
    //                 </Dropdown.Item>
    //             </Dropdown.Menu>
    //         </Dropdown>
    //     );
    // }

    renderChecklistButton() {
        const {isGeneratingChecklist} = this.state;

        return (
            <Button style={{borderRadius: "20px"}}
                    size="sm"
                    variant="outline-info"
                    className="mr-2"
                    onClick={this.handleGenerateChecklist}
                    disabled={isGeneratingChecklist}
            >
                {!isGeneratingChecklist &&
                <div>
                    <FontAwesomeIcon size="sm" icon="clipboard-check" className="mr-2"/>
                    Gerar lista de conferência
                </div>
                }

                {isGeneratingChecklist &&
                <div>
                    <Spinner animation="border" role="status" size="sm" className="mr-2"/>
                    Gerando lista de conferência
                </div>
                }
            </Button>
        );
    }

    renderActionButtons() {
        const {delivery, onEdit, onRemove} = this.props;
        const {isGeneratingTags} = this.state;

        return (
            <div style={{display: "inline-block", width: "100%"}}>
                <div className="float-right">
                    <div className="btn-group">

                        {this.renderCompleteDeliveryButton()}
                        {this.renderChecklistButton()}
                        <Button style={{borderRadius: "20px"}}
                                size="sm"
                                variant="outline-primary"
                                className="mr-2"
                                disabled={isGeneratingTags}
                                onClick={this.handleGenerateTags}
                        >
                            {!isGeneratingTags &&
                            <div>
                                <FontAwesomeIcon icon="tags" size="sm" className="mr-2"/>
                                Gerar etiquetas
                            </div>}

                            {isGeneratingTags &&
                            <div>
                                <Spinner animation="border" role="status" size="sm" className="mr-2"/>
                                Gerando etiquetas
                            </div>}

                        </Button>

                        <Button style={{borderRadius: "20px"}}
                                size="sm"
                                variant="outline-secondary"
                                className="mr-2"
                                onClick={() => onEdit(delivery)}
                        >
                            <FontAwesomeIcon icon="edit" size="sm" className="mr-2"/>
                            Editar
                        </Button>

                        <Button
                            style={{borderRadius: "20px"}}
                            size="sm"
                            variant="outline-danger"
                            onClick={() => onRemove(delivery)}
                        >
                            <FontAwesomeIcon icon="times" size="sm" className="mr-2"/>
                            Apagar
                        </Button>
                    </div>

                </div>
            </div>
        );
    }

    renderDeliveryOrdersItems() {
        const {delivery, isAccordionOpen} = this.props;
        const {isLoadingOrders} = this.state;

        return (
            <div className="DeliveryItemContent-order-content">
                <div className="bigtitle">Pedidos</div>
                <DeliveryOrders orders={delivery.orders} isLoading={isLoadingOrders} isAccordionOpen={isAccordionOpen}/>
            </div>
        );
    }

    renderDeliveryDates() {
        const {delivery} = this.props;

        return (
            <div className="DeliveryItemContent-order-content">
                <div className="bigtitle">Data de entrega</div>
                <div>
                    <div className="subtitle">Agendado para</div>
                    <div className="title">{this.displayDate(delivery.expectedDeliveryDate)}</div>
                </div>
                {delivery.deliveryDate && <div>
                    <div className="subtitle">Entregue em</div>
                    <div className="title">{this.displayDate(delivery.deliveryDate)}</div>
                </div>}
            </div>
        );
    }

    renderDeliveryStatus() {
        const {delivery} = this.props;

        let iconClass;
        let state;
        switch (delivery.status) {
            case "CANCELED":
                iconClass = "DeliveryItem-canceled-icon";
                state = "Cancelado";
                break;
            case "DELIVERED":
                iconClass = "DeliveryItem-active-icon";
                state = "Entregue";
                break;
            case "NOT_DELIVERED":
            default:
                iconClass = "DeliveryItem-inactive-icon";
                state = "Aguardando entrega";
                break;
        }

        return (
            <div className="DeliveryItemContent-order-content">
                <div className="bigtitle">Estado</div>
                <div>
                    <FontAwesomeIcon
                        className={`${iconClass} mr-2`}
                        size="1x"
                        icon="truck-loading"
                    />
                    <label className="title">{state}</label>
                </div>
            </div>
        );
    }

    renderPixKey() {
        const {delivery} = this.props;
        if (delivery.pixKey == null) {
            return null;
        }

        return (
            <div className="DeliveryItemContent-order-content">
                <div>
                    <div className="bigtitle">Pix</div>
                    <div className="title">{delivery.pixKey.value}</div>
                </div>
            </div>
        )
    }

    renderOrderNote() {
        const {delivery} = this.props;
        if (delivery.note == null) {
            return null;
        }

        return (
            <div className="DeliveryItemContent-order-content">
                <div>
                    <div className="bigtitle">Observações</div>
                    <div className="subtitle">{delivery.note}</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.renderActionButtons()}
                <Row>
                    <Col md={6}>
                        {this.renderDeliveryOrdersItems()}
                    </Col>
                    <Col md={6}>
                        {this.renderDeliveryDates()}
                        {this.renderDeliveryStatus()}
                        {this.renderPixKey()}
                        {this.renderOrderNote()}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default DeliveryItemContent;