import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import React from "react";
import './stocks-table.scss';
import IconButton from "../../utils/icon-button/icon-button";

const StocksTable = (props) => {
    const {stocks, onAdd, onSub} = props;

    const columns = [
        {
            dataField: 'product.name',
            text: 'Produto',
            align: 'center',
            headerAlign: 'center',
            sort: true,
            sortFunc: (a, b, order) => {
                if (order === 'asc') {
                    return (a > b) - (a < b)
                }
                return (a < b) - (a > b) // desc
            }
        },
        {
            dataField: 'amount',
            text: 'Quantidade',
            align: 'center',
            headerAlign: 'center',
            sort: true,
            sortFunc: (a, b, order) => {
                if (order === 'asc') {
                    return (a > b) - (a < b)
                }
                return (a < b) - (a > b) // desc
            },
            formatter: (cellContent, row) => {
                const {amount, product} = row;
                if (amount === 0) {
                    return "-";
                }

                return `${amount.toFixed(2)} ${product.measurementUnit}`;
            }
        },
        {
            dataField: 'actions',
            isDummyField: true,
            text: 'Ações',
            align: 'center',
            headerAlign: 'center',
            formatter: (cellContent, row) => {
                return (
                    <div>
                        <IconButton icon="plus" variant="outline-primary" onClick={() => onAdd(row)}/>
                        <IconButton icon="minus" variant="outline-danger" onClick={() => onSub(row)}/>
                    </div>
                );
            }
        }
    ];

    const defaultSorted = [{
        dataField: "product.name",
        order: "asc"
    }];

    return (
        <div className="StocksTable">
            <BootstrapTable
                striped
                bootstrap4
                keyField={"id"}
                data={stocks}
                columns={columns}
                bordered={false}
                pagination={paginationFactory()}
                defaultSorted={defaultSorted}
                size="sm"
            />
        </div>
    )
}

export default StocksTable;