import React, {Component} from "react";
import autoBind from "auto-bind";
import {Button, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withRouter} from "react-router-dom";
import ClientsRemote from "../../resources/remote/clients-remote";
import ClientsTable from "./table/clients-table/clients-table";
import alertify from "alertifyjs";
import _ from "lodash";


class ClientsContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            isLoading: false,
        };

        autoBind(this);
    }

    componentDidMount() {
        this.setState({isLoading: true});
        ClientsRemote.list()
            .then((clients) => {
                this.setState({isLoading: false, clients});
            });
    }

    handleOpenPageCreateClient() {
        const {history} = this.props;
        history.push("/clients/create");
    }

    handleClosePageCreateClient() {
        const {history} = this.props;
        history.push("/clients");
    }

    handleClientRemove(client) {
        const removeClient = () => {
            ClientsRemote.remove(client.idClient)
                .then((response) => {
                    const {clients} = this.state;
                    clients.splice(clients.indexOf(client), 1);
                    this.setState({clients});
                    alertify.success("Cliente removido!");
                })
                .catch((error) => {
                    const message = "Não foi possível deletar o cliente. " + error;
                    alertify.error(message);
                });
        }

        alertify.confirm(
            "Exclur cliente: " + client.name,
            "A exclusão do cliente '" + client.name + "' não poderá ser desfeita.",
            removeClient,
            _.noop
        );
    }

    handleClientEdit(client) {
        const {history} = this.props;
        history.push(`/clients/update/${client.idClient}`);
    }

    renderClientsTable() {
        const {isLoading, clients} = this.state;

        if (isLoading) {
            return this.renderLoadingComponent();
        }

        return (
            <ClientsTable
                clients={clients}
                onRemove={this.handleClientRemove}
                onEdit={this.handleClientEdit}
            />
        );
    }

    renderLoadingComponent() {
        return (
            <div style={{color: "grey", textAlign: "center"}}>
                <div><Spinner animation="border"/></div>
                <div>Carregando clientes</div>
            </div>
        );
    }

    render() {
        return (
            <div>
                <div style={{display: "inline-block", width: "100%"}}>
                    <h3 className='float-left'>Clientes</h3>
                    <Button variant={"primary"} size="md" className='float-right' onClick={this.handleOpenPageCreateClient}>
                        <FontAwesomeIcon size="sm" icon="plus" style={{marginRight: "8px"}}/>
                        Adicionar cliente
                    </Button>
                </div>
                {this.renderClientsTable()}
            </div>
        );
    }
}

export default withRouter(ClientsContent);