import React, {Component} from "react";
import autoBind from "auto-bind";
import {Button, Col, Form, Spinner} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import NumberFormat from "react-number-format"
import _ from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ClientsDeliveryAddressModal from "../modal/clients-delivery-address-modal";
import ClientsDeliveryAddressTable from "../table/clients-delivery-address-table/clients-delivery-address-table";
import ClientsRemote from "../../../resources/remote/clients-remote";
import alertify from "alertifyjs";


class ClientsCreateClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: {
                submited: false,
                cellphone: false,
            },
            idClient: null,
            name: null,
            cellphone: null,
            deliveryAddresses: [],
            actions: {
                showCreateDeliveryAddressModal: false,
                showEditDeliveryAddressModal: false,
                isSaving: false,
            },
            deliveryAddressToEdit: null,
        };

        this._isEditMode = false;
        this._autoFocusComponentRef = null;

        autoBind(this);
    }

    async componentWillMount() {
        const {match} = this.props;
        const {validated} = this.state;

        const idClient = match.params.idClient;

        if (!_.isNil(idClient)) {
            const client = await ClientsRemote.get(idClient);
            this._isEditMode = true;

            Object.keys(validated).forEach(key => validated[key] = true);
            this.setState({...client});
        }
    }

    componentDidMount() {
        this._autoFocusComponentRef.focus();
    }

    clearState() {
        const state = {
            validated: {
                submited: false,
                cellphone: false,
            },
            name: null,
            cellphone: null,
            deliveryAddresses: [],
            actions: {
                showCreateDeliveryAddressModal: false,
                showEditDeliveryAddressModal: false,
            },
            deliveryAddressToEdit: null,
        };
        this.setState(state);
    }

    isFormValidated() {
        const {validated} = this.state;
        return Object.values(validated).reduce((result, value) => result && value);
    }

    isValidCellphone(cellphone) {
        return cellphone.match(/\([0-9]{2}\) [0-9] [0-9]{4} [0-9]{4}/) != null;
    }

    closePage() {
        const {history} = this.props;
        history.push("/clients");
    }

    getDefaultValue(path) {
        if (this._isEditMode) {
            return _.get(this.state, path, undefined);
        }
        return undefined;
    }

    handleSubmit(event) {
        const {actions, idClient, name, cellphone, deliveryAddresses, validated} = this.state;

        event.preventDefault();
        event.stopPropagation();

        validated.submited = true;
        this.setState({validated});

        if (this.isFormValidated()) {
            const client = {idClient, name, cellphone, deliveryAddresses};
            actions.isSaving = true;
            this.setState({actions});

            const save = this._isEditMode ? ClientsRemote.update : ClientsRemote.create;
            save(client)
                .then((client) => {
                    this.closePage();
                    this.clearState();
                    alertify.success(this._isEditMode ? "Cliente atualizado!" : "Cliente adicionado!");
                })
                .catch((error) => {
                    alertify.error("Não foi possível adicionar cliente. " + error);
                })
                .finally(() => {
                    actions.isSaving = false;
                    this.setState({actions});
                });
        }
    }

    handleOpenCreateDeliveryAddressModal() {
        const actions = {
            showCreateDeliveryAddressModal: true
        };
        this.setState({actions});
    }

    handleOpenEditDeliveryAddressModal(deliveryAddressToEdit) {
        const actions = {
            showEditDeliveryAddressModal: true
        };
        this.setState({actions, deliveryAddressToEdit});
    }

    handleCloseDeliveryAddressModal() {
        const actions = {
            showCreateDeliveryAddressModal: false,
            showEditDeliveryAddressModal: false
        };
        this.setState({actions});
    }

    handleCreateDeliveryAddress(deliveryAddress) {
        const {deliveryAddresses} = this.state;
        deliveryAddresses.push(deliveryAddress);
        this.setState({deliveryAddresses});
    }

    handleEditDeliveryAddress(oldDeliveryAddress, newDeliveryAddress) {
        const {deliveryAddresses} = this.state;
        deliveryAddresses.splice(deliveryAddresses.indexOf(oldDeliveryAddress), 1, newDeliveryAddress);
        this.setState({deliveryAddresses});
    }

    handleRemoveDeliveryAddress(deliveryAddress) {
        const {deliveryAddresses} = this.state;
        deliveryAddresses.splice(deliveryAddresses.indexOf(deliveryAddress), 1);
        this.setState({deliveryAddresses});
    }

    handleNameChange(event) {
        const name = event.target.value;
        this.setState({name});
    }

    handleCellphoneChange(event) {
        const {validated} = this.state;
        const cellphone = String(event.target.value);

        validated.cellphone = this.isValidCellphone(cellphone);
        this.setState({cellphone, validated});
    }

    renderNameField() {
        return (
            <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                    type="text"
                    required
                    onChange={this.handleNameChange}
                    defaultValue={this.getDefaultValue("name")}
                    ref={(input) => {this._autoFocusComponentRef = input;}}
                />
            </Form.Group>
        );
    }

    renderCellphoneField() {
        const {validated} = this.state;
        return (
            <Form.Group>
                <Form.Label>Celular</Form.Label>
                <Form.Control
                    type="text"
                    required
                    as={NumberFormat}
                    format="(##) # #### ####"
                    mask="_"
                    onChange={this.handleCellphoneChange}
                    isInvalid={validated.submited && !validated.cellphone}
                    value={this.getDefaultValue("cellphone")}
                />
                <Form.Control.Feedback type="invalid">Este não é um número de celular válido!</Form.Control.Feedback>
            </Form.Group>
        );
    }

    renderPersonalInformationFields() {
        return (
            <Form.Group>
                <Form.Label>Informações pessoais</Form.Label>
                <Form.Row>
                    <Col md={8}>{this.renderNameField()}</Col>
                    <Col md={4}>{this.renderCellphoneField()}</Col>
                </Form.Row>
            </Form.Group>
        )
    }

    renderDeliveryAddressesTable() {
        const {deliveryAddresses} = this.state;

        if (_.isEmpty(deliveryAddresses)) {
            return (
                <div>Nenhum endereço de entrega cadastrado.</div>
            );
        }

        return (
            <ClientsDeliveryAddressTable
                deliveryAddresses={deliveryAddresses}
                onEdit={this.handleOpenEditDeliveryAddressModal}
                onRemove={this.handleRemoveDeliveryAddress}
            />
        );
    }

    renderNewDeliveryAddressButton() {
        return (
            <Button className="float-right" onClick={this.handleOpenCreateDeliveryAddressModal}>
                <FontAwesomeIcon size="sm" icon="plus" className="mr-2"/>
                Adicionar endereço de entrega
            </Button>
        );
    }

    renderDeliveryAddresses() {
        return (
            <div>
                <div style={{display: "inline-block", width: "100%"}}>
                    <Form.Group>
                        <Form.Label>Endereços de entrega</Form.Label>
                        {this.renderNewDeliveryAddressButton()}
                    </Form.Group>
                </div>
                {this.renderDeliveryAddressesTable()}

            </div>
        );
    }

    renderCloseButton() {
        const {actions} = this.state;
        return (
            <Button className="float-left" variant="outline-secondary" disabled={actions.isSaving} onClick={this.closePage}>
                Voltar
            </Button>
        );
    }

    renderSaveButton() {
        const {actions} = this.state;
        return (
            <Button className="float-right" variant="success" type="submit" disabled={actions.isSaving}>
                {actions.isSaving ?
                    <div>
                        <Spinner animation="border" role="status" size="sm" className="mr-2"/>
                        Salvando
                    </div> : <div>Salvar</div>
                }
            </Button>
        );
    }

    renderDeliveryAddressModal(isEditMode) {
        const {actions, deliveryAddressToEdit} = this.state;
        const show = isEditMode ? actions.showEditDeliveryAddressModal : actions.showCreateDeliveryAddressModal;
        return (
            <ClientsDeliveryAddressModal
                show={show}
                onCreate={this.handleCreateDeliveryAddress}
                onEdit={this.handleEditDeliveryAddress}
                onClose={this.handleCloseDeliveryAddressModal}
                isEditMode={isEditMode}
                deliveryAddress={isEditMode ? deliveryAddressToEdit : null}
            />
        );
    }

    renderCreateDeliveryAddressModal() {
        return this.renderDeliveryAddressModal(false);
    }

    renderEditDeliveryAddressModal() {
        return this.renderDeliveryAddressModal(true);
    }

    render() {
        return (
            <div className="ClientsCreateClient">
                <div style={{display: "inline-block", width: "100%"}}>
                    <h3 className='float-left'>{this._isEditMode ? "Editar" : "Adicionar"} cliente</h3>
                </div>
                <Form onSubmit={this.handleSubmit}>
                    {this.renderPersonalInformationFields()}
                    {this.renderDeliveryAddresses()}
                    <div style={{margin: "16px 0"}}>
                        {this.renderCloseButton()}
                        {this.renderSaveButton()}
                    </div>

                </Form>
                {this.renderCreateDeliveryAddressModal()}
                {this.renderEditDeliveryAddressModal()}
            </div>
        );
    }
}

export default withRouter(ClientsCreateClient);