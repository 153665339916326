import _ from "lodash";
import autoBind from "auto-bind";
import React, {Component} from "react";
import {Button, Col, Form, InputGroup, Spinner} from "react-bootstrap";
import SelectWrapper from "../../../../utils/select-wrapper/select-wrapper";

class OrdersCreateOrderAddProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: null,
            amount: null
        };

        autoBind(this);
    }

    clearState() {
        this.setState({
            product: null,
        });
    }

    getProductSelectOptions() {
        const {products} = this.props;
        return _.orderBy(products, ["name"], ["asc"])
            .map(product => (
                {
                    value: product.idProduct,
                    label: product.name,
                }
            ));
    }

    getAmountMeasurementUnit() {
        const {product} = this.state;

        if (product == null) {
            return "  ";
        }

        return product.measurementUnit;
    }

    handleSubmit(event) {
        const {onAdd} = this.props;
        const {product, amount} = this.state;
        event.preventDefault();
        event.stopPropagation();

        const orderItem = {product, amount};
        onAdd(orderItem);
        this.clearState()
    }

    handleProductChange(event) {
        const {products} = this.props;
        const idProduct = event.value;
        const product = _.find(products, _.matchesProperty("idProduct", idProduct));
        this.setState({product});
    }

    handleAmountChange(event) {
        const amount = parseFloat(event.target.value);
        this.setState({amount});
    }

    renderProductField() {
        const {product} = this.state;
        const value = product != null ? {value: product.idProduct, label: product.name} : null;
        return (
            <Form.Group>
                <Form.Label>Produto</Form.Label>
                <SelectWrapper
                    isSearchable
                    options={this.getProductSelectOptions()}
                    placeholder="Selecione uma opção"
                    required
                    onChange={this.handleProductChange}
                    value={value}
                />
            </Form.Group>
        );
    }

    renderAmountField() {
        const {amount} = this.state;
        return (
            <Form.Group>
                <Form.Label>Quantidade</Form.Label>
                <InputGroup>
                    <Form.Control
                        type="number"
                        required
                        min={0}
                        step="0.01"
                        onChange={this.handleAmountChange}
                        value={amount}
                    />
                    <InputGroup.Append>
                        <InputGroup.Text id="inputGroupPrepend">{this.getAmountMeasurementUnit()}</InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
            </Form.Group>
        );
    }

    renderLoadingComponent() {
        const {isLoading} = this.props;
        if (isLoading) {
            return (
                <div style={{color: "grey", textAlign: "center", marginLeft: "12px"}}>
                    <div><Spinner animation="border"/></div>
                </div>
            );
        }
    }

    render() {
        const {isLoading} = this.props;
        return (
            <Form id="orders-create-order-add-product-form" onSubmit={this.handleSubmit}>
                <Form.Group>
                    <Form.Row>
                        <Col md={4}>
                            {this.renderAmountField()}
                        </Col>
                        <Col md={8}>
                            {this.renderProductField()}
                        </Col>
                    </Form.Row>
                    <div className="float-right">
                        {this.renderLoadingComponent()}
                    </div>
                    <Button type="submit" className="float-right" disabled={isLoading}>Adicionar</Button>
                </Form.Group>
            </Form>
        );
    }
}

export default OrdersCreateOrderAddProduct;