import * as LocalStorage from "./localStorage";
import * as LoginRemote from "./remote/login-remote";

const _login = {
    email: null,
    password: null,
};

let _refreshToken = null;
const _logoutCallbacks = [];


const _startRefreshToken = (email, password, interval) => {
    _refreshToken = setInterval(() => {
        LoginRemote.login(email, password)
            .then(({token, duration}) => {
                _login.token = token;
                _login.duration = duration;

                LocalStorage.setToken(token);
                LocalStorage.setDuration(duration);
                LocalStorage.setTimestamp(Date.now().toString());
            })
    }, interval * 1000);
}

const _stopRefreshToken = () => {
    clearInterval(_refreshToken);
}

const LoginStorage = {
    getEmail: () => _login.email,
    getPassword: () => _login.password,
    addLogoutCallback: callback => {
        _logoutCallbacks.push(callback);
    },
    login: ({email, password, token, duration}) => {
        _login.email = email;
        _login.password = password;
        _login.token = token;
        _login.duration = duration;

        LocalStorage.setToken(token);
        LocalStorage.setDuration(duration);
        LocalStorage.setTimestamp(Date.now().toString());

        // Renova token 5 minutos antes de expirar
        const refreshInterval = duration > 300 ? duration - 300 : duration;
        _startRefreshToken(email, password, refreshInterval);
    },
    logout: () => {
        _logoutCallbacks.forEach(callback => callback());
        _stopRefreshToken();
        LocalStorage.clear();
    },
    isLogged: () => {
        const token = LocalStorage.getToken();
        const duration = LocalStorage.getDuration();

        if (token == null || duration == null) {
            return false;
        }

        const durationInMs = duration * 1000;
        const timestamp = parseInt(LocalStorage.getTimestamp());

        return Date.now() - timestamp <= durationInMs;
    }
};

Object.freeze(LoginStorage);

export default LoginStorage;