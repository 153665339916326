import React from "react";
import "./orders-order-item.scss";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const OrdersOrderItem = (props) => {
    const {orderItem} = props;
    const {product, amount} = orderItem;

    const displayValue = (value) => {
        if (value == null) {
            return parseFloat("0").toFixed(2).replace(".", ",");
        }
        return value.toFixed(2).replace(".", ",");
    }

    const handleOrderItemRemove = () => {
        const {index, onRemove} = props;
        onRemove(index);
    }

    const renderRemoveButton = () => {
        const {onRemove} = props;
        if (onRemove == null) {
            return;
        }

        return (
            <div className="float-right" style={{marginLeft: "12px"}}>
                <Button
                    variant="danger"
                    size="sm"
                    style={{padding: "2px 6px 0 6px"}}
                    onClick={handleOrderItemRemove}
                >
                    <FontAwesomeIcon icon="times"/>
                </Button>
            </div>
        );
    }

    return (
        <div className="OrdersOrderItem" style={{display: "flex", flexWrap: "wrap"}}>
            <div style={{flexGrow: 1}}>
                <div className="float-left">
                    <div className="OrdersOrderItem-subtitle">Produto</div>
                    <div>
                        <label>{product.name}</label>
                        <label style={{fontSize: "12px", color: "gray", marginLeft: "5px"}}>
                            {amount} {product.measurementUnit} / R$ {displayValue(product.price)}
                        </label>
                    </div>
                </div>

                <div className="float-right" style={{display: "flex", alignItems: "center"}}>
                    <div className="float-left">
                        <div className="OrdersOrderItem-subtitle-right">Valor</div>
                        <div className="float-right">R$ {displayValue(amount * product.price)}</div>
                    </div>

                    {renderRemoveButton()}
                </div>
            </div>
        </div>
    );
}

export default OrdersOrderItem;