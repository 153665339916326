import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import "./icon-button.scss";
import {Button} from "react-bootstrap";

const IconButton = (props) => {
    const {icon, variant, onClick} = props;
    return (
        <div className="IconButton">
            <Button size="sm" variant={variant} onClick={onClick}>
                <FontAwesomeIcon size="1x" icon={icon}/>
            </Button>
        </div>
    );
}

export default IconButton;